/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { Button, Group, TextInput } from '@mantine/core';

import { SearchIcon, PlusSignIcon } from 'assets/svg';
import { CardByUser } from 'services/flexbase/banking.model';
import { IsComptroller } from 'states/application/product-onboarding';
import { Filter } from '../../components/filter/filter-models';

type Props = {
  filterText: string;
  filterList: Filter[];
  debitCards: CardByUser[];
  onClickButton: (e: any) => void;
  setFilterText: (e: any) => void;
  setFilterList: (x: Filter[]) => void;
  accountId?: string;
};

const DebitCardsHeader = (props: Props) => {
  const isComptroller = useRecoilValue(IsComptroller);
  const useMobileView = useMediaQuery('(max-width: 767px)');

  return (
    <Group
      position="apart"
      mb="xl"
      display="flex"
      style={{
        flexDirection: props.accountId
          ? 'row'
          : useMobileView
            ? 'column-reverse'
            : 'initial',
      }}
    >
      <TextInput
        icon={<SearchIcon />}
        value={props.filterText}
        placeholder="Search debit cards"
        w={useMobileView ? '100%' : 500}
        onChange={(e) => props.setFilterText(e.target.value)}
      />
      {isComptroller && !props.accountId && (
        <Button
          variant="light"
          fullWidth={useMobileView}
          onClick={props.onClickButton}
          leftIcon={<PlusSignIcon width={10} />}
          data-testid="add-debit-card"
        >
          New debit card
        </Button>
      )}
    </Group>
  );
};

export default DebitCardsHeader;
