import FlexbaseInput from 'components/input/flexbase-input';
import { useOnboardingStyles } from '../../onboarding.styles';
import { useState } from 'react';
import { useForm } from '@mantine/form';
import { RequiredFieldValidator } from '../../../../utilities/validators/validate-required';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../states/application/product-onboarding';
import OwnerOfficerForm from 'areas/onboarding-v2/components/owner-officer-form-box';
import { useCreatePlatformBusinessOwnerMutation } from '../../../../queries/use-platform-business';
import { platformClient } from '../../../../services/platform/platform-client';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { ParsePlatformError } from '../../../../services/platform/models/identity.model';

type Props = {
  onSuccess: () => void;
  totalOwnershipPercent: number;
  platformAccountId: string;
};

const BeneficialOwnerForm = ({ onSuccess, totalOwnershipPercent }: Props) => {
  const { classes } = useOnboardingStyles();
  const { company, accountId, businessId } = useRecoilValue(ApplicationState);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { mutate: createPlatformBusinessOwner } =
    useCreatePlatformBusinessOwnerMutation();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      ownershipPct: '',
    },
    validate: {
      firstName: RequiredFieldValidator('First name is required'),
      lastName: RequiredFieldValidator('Last name is required'),
      email: RequiredFieldValidator('Email is required'),
      ownershipPct: (value) => {
        const asNum = Number(value);
        return !asNum || asNum < 1 ? 'Ownership % must 1 or more' : null;
      },
    },
  });
  const setOwnershipPercent = (value: string) => {
    if (!value || Number(value)) {
      form.setFieldValue('ownershipPct', value);
    }
  };

  const onSubmit = async () => {
    setError('');
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      const ownershipPercent = Number(form.values.ownershipPct);

      const newTotal = totalOwnershipPercent + ownershipPercent;
      if (newTotal > 100) {
        form.setFieldError(
          'ownershipPct',
          `Total ownership % for all owners cannot exceed 100%. (Currently ${newTotal}%)`,
        );
      } else {
        // Attempting owner add
        try {
          setLoading(true);
          const formValues = form.values;
          const request = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            companyId: company.id,
            roles: ['OWNER'],
          };

          const addedOwner = await platformClient.inviteUser(
            accountId,
            formValues.email,
            'signatory',
            request,
          );

          const addedOwnerPersonId = addedOwner.person.id;

          await flexbaseOnboardingClient.updateCompany({
            id: company.id,
            owners: [
              ...company.owners.filter((o) => o.id !== addedOwner.person.id), // Probably not necessary but better safer than sorrier
              { id: addedOwnerPersonId, ownershipPct: ownershipPercent },
            ],
          });

          createPlatformBusinessOwner({
            accountId,
            businessId,
            personId: addedOwnerPersonId,
            percentage: ownershipPercent,
          });
          onSuccess();
        } catch (e) {
          const asPlatformError = ParsePlatformError(e);
          if (asPlatformError && asPlatformError.statusCode === 409) {
            setError(
              'A person with this email address already exists in our system. If this person belongs to your company, please contact support.',
            );
          } else if (
            e?.text &&
            typeof e.text === 'string' &&
            e.text.toLowerCase().includes('already in use')
          ) {
            setError('A user with this email already exists.');
          } else {
            setError(`An error occurred, could not add owner.`);
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const onClear = () => {
    form.reset();
  };

  return (
    <OwnerOfficerForm
      form={form}
      type="owner"
      loading={loading}
      onCancel={onClear}
      onSubmit={onSubmit}
      errorMessage={error}
      title="Add Beneficial Owner"
    >
      <FlexbaseInput
        classNames={{
          label: classes.inputLabel,
          root: classes.btnSpacingAddressPage,
        }}
        placeholder="%"
        label={'% Ownership'}
        {...form.getInputProps('ownershipPct')}
        data-sardine-id="input-owner-ownership-percent"
        onChange={(e) => setOwnershipPercent(e.target.value)}
      />
    </OwnerOfficerForm>
  );
};

export default BeneficialOwnerForm;
