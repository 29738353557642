import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { FacebookPixel } from '../../../../services/analytics/pixel';
import { useQueryParams } from '../../../../utilities/url/query-param';
import { Analytics } from '../../../../services/analytics/analytics';
import { RegistrationProductsState } from '../../../../states/application/product-onboarding';
import { RegistrationPage } from '../../pages/registration/registration.page';
import { useProvePrefillFeatureFlag } from '@utilities/feature-flags';
import { CreditAndBankingStart } from './credit-and-banking.start';
import { Box } from '@mantine/core';

const StartScreen = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const query = useQueryParams();
  const setProducts = useSetRecoilState(RegistrationProductsState);
  const { treatmentOn: prefillFeatureEnabled, treatmentReady } =
    useProvePrefillFeatureFlag();

  const segmentAnon = searchParams.get('segment_anon');
  const variant = searchParams.get('variant') ?? 'control';

  useEffect(() => {
    if (variant) {
      localStorage?.setItem('variant', variant);
      Analytics.track('Marketing Registration Variant', {
        variant,
      });
    }
  }, [variant]);

  if (segmentAnon) {
    localStorage.setItem('segment_anon', segmentAnon);
  }

  useEffect(() => {
    FacebookPixel.pageView();
  }, [location]);

  useEffect(() => {
    const product = query.get('product');
    // If there is a query param, always set the product, even if one was previously set.
    if (product) {
      switch (product) {
        case 'banking':
          setProducts(['BANKING']);
          break;
        case 'credit':
          setProducts(['CREDIT']);
          break;
        case 'all':
          setProducts(['CREDIT', 'BANKING']);
          break;
      }
    } else if (!product) {
      // If there is no query param, no BNPL, and a product was not prev set, default to credit+banking
      setProducts((prev) => {
        if (prev.length > 0) {
          return prev;
        }

        return ['CREDIT', 'BANKING'];
      });
    }
  }, [query]);

  // If the SplitClient is not ready yet, show a plain background to prevent pop-in of conditional content below
  if (!treatmentReady) {
    return <Box w="100%" h="100vh" bg="primarySecondarySuccess.8" />;
  }

  const prefillFeatureFlagOverride = query.get('prefill');

  const showPrefill =
    prefillFeatureFlagOverride === 'yes' ||
    (prefillFeatureEnabled && prefillFeatureFlagOverride !== 'no');

  return showPrefill ? <RegistrationPage /> : <CreditAndBankingStart />;
};

export default StartScreen;
