import { useOnboardingStyles } from '../../onboarding.styles';
import {
  BUSINESS_ANNUAL_REVENUE,
  BUSINESS_MERCHANT_CODES,
  BUSINESS_VERTICALS,
  NAICS_CODES,
} from '../../../../states/business/constants';
import { ConditionalFieldValidator } from '../../../../utilities/validators/validate-required';
import { useForm } from '@mantine/form';
import { validateUrl } from '../../../../utilities/validators/validate-url';
import { useRecoilValue } from 'recoil';
import OnboardingStep from '../../components/onboarding-step';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../../states/application/product-onboarding';
import { Autocomplete, Group, Radio, Text, TextInput } from '@mantine/core';
import { Analytics } from '../../../../services/analytics/analytics';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { filterAutocompleteData } from '../../../../utilities/filter/filter-data';
import { RequiredFieldValidator } from '@flexbase-eng/web-components';
import TagManager from 'react-gtm-module';
import { formatCurrencyInput } from '../../../../utilities/formatters/format-currency';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { useUpdatePlatformBusinessMutation } from '../../../../queries/use-platform-business';

const BusinessActivity = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { navigateToNextProductStep, goBack, createOrUpdateCompany } =
    useApplicationFlowContext();
  const { mutate: updatePlatformBusiness } =
    useUpdatePlatformBusinessMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mcc, setMcc] = useState<{ value: string; label: string }[]>([]);
  const { user, company, optedProducts, productStatus, accountId, businessId } =
    useRecoilValue(ApplicationState);
  const { credit } = productStatus;

  const getCodes = async () => {
    setLoading(true);
    try {
      const code = await flexbaseOnboardingClient.getMccCodes();
      setMcc(code.map((c) => ({ value: c.code, label: c.description })));
    } catch (e) {
      setError('Unable to load full business category data');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCodes();
  }, []);

  const businessActivityForm = useForm({
    initialValues: {
      companyName: company.companyName
        ? company.companyName === 'My Company'
          ? ''
          : company.companyName
        : '',
      hasWebsite: company.website
        ? 'Yes'
        : company.website === null
          ? 'No'
          : '',
      website: company.website || '',
      category: company.category || '',
      annualRevenue: company.annualRevenue || '',
      businessPurpose: company.businessPurpose || '',
      naicsCode: company.naicsCode || '',
      businessVertical: company.businessVertical || '',
      doingBusinessAs: company.doingBusinessAs || '',
      requestedCreditLimit: company.requestedCreditLimit || '',
    },
    validate: {
      hasWebsite: ConditionalFieldValidator(optedProducts.includes('BANKING')),
      website: (val, formValues) => {
        // TODO fix validation for credit product
        if (val || formValues.hasWebsite === 'Yes') {
          return validateUrl(val) ? null : 'Must be a valid URL';
        }

        return null;
      },
      companyName: (val) =>
        val === 'My Company'
          ? 'Default value detected. Make sure to update.'
          : RequiredFieldValidator('You must enter the name of your company')(
              val,
            ),
      category: RequiredFieldValidator('Category is required'),
      annualRevenue: RequiredFieldValidator('Annual revenue is required'),
      businessPurpose: RequiredFieldValidator('Business purpose is required'),
      naicsCode: ConditionalFieldValidator(optedProducts.includes('TREASURY')),
      businessVertical: ConditionalFieldValidator(
        optedProducts.includes('BANKING'),
      ),
    },
  });

  const onRequestedCreditChange = (value: string) => {
    const formatted = formatCurrencyInput(value);
    if (formatted !== undefined) {
      businessActivityForm.setFieldValue('requestedCreditLimit', formatted);
    }
  };

  const onSubmit = async () => {
    const validationResult = businessActivityForm.validate();
    if (!validationResult.hasErrors) {
      setError('');
      setLoading(true);
      const formValues = businessActivityForm.values;

      // Due to some oddness with the Mantine autocomplete and how we want to display data, need to do this
      if (formValues.category.includes('-')) {
        formValues.category = formValues.category.split('-')?.[0]?.trim();
      }

      if (user.id) {
        Analytics.people.set(user.id, {
          ...formValues,
        });
      }
      const updateValues = {
        companyName: formValues.companyName,
        businessVertical: formValues.businessVertical,
        category: formValues.category,
        annualRevenue: formValues.annualRevenue,
        businessPurpose: formValues.businessPurpose,
        naicsCode: formValues.naicsCode,
        website: formValues.website || (null as any),
        ...(formValues.requestedCreditLimit && {
          requestedCreditLimit: formValues.requestedCreditLimit.replace(
            ',',
            '',
          ),
        }),
      };
      let canNavigateToNextStep = true;
      if (company.id) {
        const result = await createOrUpdateCompany(updateValues);
        updatePlatformBusiness({
          accountId,
          businessId,
          request: {
            name: formValues.companyName,
            mcc: formValues.category,
            dba: formValues.doingBusinessAs
              ? formValues.doingBusinessAs
              : undefined,
            // website: formValues.website ? formValues.website : undefined, // Platform URL validation is too aggressive and is going to 400 in 99% of real world cases
            naicsCode: formValues.naicsCode ? formValues.naicsCode : undefined,
          },
        });
        canNavigateToNextStep = result.success;
      }
      if (canNavigateToNextStep) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'businessActivitySubmitted',
          },
        });
        navigateToNextProductStep();
      } else {
        setError('Unable to update business data');
      }
    }
  };

  const onBack = () => {
    goBack();
  };

  const [searchString, setSearchString] = useState('');
  const naicsAutocompleteData = filterAutocompleteData(
    searchString,
    NAICS_CODES,
  );
  const mccAutocompleteData = filterAutocompleteData(
    searchString,
    mcc.length > 0 ? mcc : BUSINESS_MERCHANT_CODES,
  );

  const categoryLabel = (value: string) => {
    const category = mcc.find((code) => code.value === value);
    return category ? `${category?.value} - ${category?.label}` : value;
  };

  const disableWebsiteInput =
    optedProducts.includes('BANKING') &&
    businessActivityForm.values.hasWebsite !== 'Yes';

  return (
    <OnboardingStep
      title="Business activity"
      subtitle="Tell us about your business"
      onBackClick={onBack}
      onNextClick={onSubmit}
      stepId="business-activity"
      showContinueSpinner={loading}
      error={error}
    >
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        data-sardine-id="input-business-legal-name"
        label="Legal entity name"
        placeholder="Enter your exact legal entity name"
        {...businessActivityForm.getInputProps('companyName')}
        id="input-business-legal-name"
      />
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="DBA (Optional)"
        data-sardine-id="input-business-dba"
        placeholder="Enter your DBA (Doing Business As)"
        {...businessActivityForm.getInputProps('doingBusinessAs')}
        id="input-business-dba"
      />
      {optedProducts.includes('BANKING') && (
        <Radio.Group
          label="Do you have a website?"
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          mt={16}
          {...businessActivityForm.getInputProps('hasWebsite')}
          onChange={(val) => {
            businessActivityForm.setFieldValue('hasWebsite', val);
            if (val === 'No') {
              businessActivityForm.setFieldValue('website', '');
            }
          }}
        >
          <Group mt={4}>
            <Radio
              value="Yes"
              label="Yes"
              data-testid="radio-button-website-yes"
              data-sardine-id="radio-button-website-yes"
            />
            <Radio
              value="No"
              label="No"
              data-testid="radio-button-website-no"
              data-sardine-id="radio-button-website-no"
            />
          </Group>
        </Radio.Group>
      )}
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="Website or social media website (optional)"
        placeholder="Enter URL for website or social media website"
        {...businessActivityForm.getInputProps('website')}
        disabled={disableWebsiteInput}
        data-sardine-id="input-business-website"
        id="input-business-website"
      />
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="Business purpose"
        placeholder="Describe the business purpose"
        {...businessActivityForm.getInputProps('businessPurpose')}
        data-sardine-id="input-business-business-purpose"
        id="input-business-business-purpose"
      />
      <Autocomplete
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        value={categoryLabel(businessActivityForm.values.category)}
        onChange={(e) => {
          setSearchString(e);
          businessActivityForm.setFieldValue('category', e);
        }}
        onItemSubmit={(i) => {
          businessActivityForm.setFieldValue('category', i.value);
        }}
        data={mccAutocompleteData}
        label="Category"
        placeholder="Search for your MCC by code or description"
        id="input-business-category"
        data-sardine-id="input-business-category"
        nothingFound="No results"
        error={businessActivityForm.errors.category}
      />
      <FlexbaseSelect
        label="Annual revenue"
        placeholder="Select range"
        mt={16}
        {...businessActivityForm.getInputProps('annualRevenue')}
        data={BUSINESS_ANNUAL_REVENUE}
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        data-sardine-id="input-business-annual-revenue"
        id="input-business-annual-revenue"
      />
      {optedProducts.includes('TREASURY') && (
        <Autocomplete
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          mt={16}
          {...businessActivityForm.getInputProps('naicsCode')}
          onChange={(e) => {
            setSearchString(e);
            businessActivityForm.setFieldValue('naicsCode', e);
          }}
          onItemSubmit={(i) => {
            businessActivityForm.setFieldValue('naicsCode', i.value);
          }}
          data={naicsAutocompleteData}
          label="NAICS Code"
          placeholder="Start typing to search for your NAICS"
          data-sardine-id="input-business-naics-code"
          id="input-business-naics-code"
          nothingFound="No results"
        />
      )}
      {optedProducts.includes('BANKING') && (
        <FlexbaseSelect
          label="Business vertical"
          placeholder="Select closest match"
          mt={16}
          {...businessActivityForm.getInputProps('businessVertical')}
          data={BUSINESS_VERTICALS}
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          id="input-business-vertical"
          data-sardine-id="input-business-vertical"
          searchable
        />
      )}
      {credit.status === 'incomplete' && !company.requestedCreditLimit && (
        <TextInput
          mt={16}
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          label="What is your desired credit limit? (Optional)"
          placeholder="ie: 20,000"
          {...businessActivityForm.getInputProps('requestedCreditLimit')}
          icon={<Text fz={14}>$</Text>}
          onChange={(e) => onRequestedCreditChange(e.target.value)}
          data-sardine-id="input-requested-credit-limit"
          id="input-requested-credit-limit"
        />
      )}
    </OnboardingStep>
  );
};

export default BusinessActivity;
