import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../states/application/product-onboarding';
import { useForm } from '@mantine/form';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { BUSINESS_ANNUAL_REVENUE } from '../../../../../states/business/constants';
import FlexbaseSelect from '../../../../../components/select/flexbase-select';
import { validateRequired } from '../../../../../utilities/validators/validate-required';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryEstimatedRevenueForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ company }, setStatus] = useRecoilState(ApplicationState);
  const form = useForm({
    initialValues: {
      annualRevenue: company.annualRevenue,
    },
    validate: {
      annualRevenue: (val) =>
        validateRequired(val) ? null : 'annualRevenue is required',
    },
  });

  const onSave = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { annualRevenue } = form.values;
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        annualRevenue,
      });
      setStatus((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          annualRevenue,
        },
      }));
    } catch (e) {
      throw 'An error occurred while trying to update company annual revenue';
    }
  };

  return (
    <SummaryEditBox
      label="Estimated annual revenue"
      value={company.annualRevenue}
      classNames={classNames}
      savePromise={onSave}
      editInputs={
        <div className={classes.inputContainer}>
          <FlexbaseSelect
            label="Annual revenue"
            id="business-revenue"
            placeholder="Select range"
            {...form.getInputProps('annualRevenue')}
            data={BUSINESS_ANNUAL_REVENUE}
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
          />
        </div>
      }
    />
  );
};

export default SummaryEstimatedRevenueForm;
