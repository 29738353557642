import { Box, Text, createStyles } from '@mantine/core';
import { formatCurrency } from '../../../../../../utilities/formatters/format-currency';
import { AvailableCurrencies } from '../util/types';
import IntlPaymentCardDetails from './intl-payment-card.Details';
import IntlPaymentCardSummary from './intl-payment-card.Summary';

type Props = {
  recipientName: string;
  currencyAmount: string | number;
  children: React.ReactNode;
  currency?: AvailableCurrencies;
  purpose?: string;
};

const IntlPaymentCard = ({
  currencyAmount,
  currency,
  children,
  recipientName,
  purpose,
}: Props) => {
  const { classes } = useStyles();
  return (
    <Box>
      <Box>
        <Text size="sm" color="neutral.7" data-testid="payment-description">
          You&apos;re sending{' '}
          <span
            className={classes.fontWeightBold}
            data-testid="currency-amount"
          >
            {formatCurrency(currencyAmount, currency)}
          </span>{' '}
          <span data-testid="currency-type">{currency} to </span>
          <span className={classes.fontWeightBold}>
            {recipientName}
          </span> for{' '}
          <span
            data-testid="payment-purpose"
            className={classes.fontWeightBold}
          >
            {purpose}
          </span>
        </Text>
      </Box>
      {children}
    </Box>
  );
};

IntlPaymentCard.Details = IntlPaymentCardDetails;
IntlPaymentCard.Summary = IntlPaymentCardSummary;
export default IntlPaymentCard;

const useStyles = createStyles(() => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
}));
