import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { Alert, Anchor, Button, Group, Text } from '@mantine/core';
import { useStyles } from '../styles';
import { InfoIcon, RedAlertIcon } from 'assets/svg';
import { PaymentForm } from 'states/banking/payments';
import AccountSelection from 'areas/payments/components/account-selection';
import { AccountProps, DepositAccount, PlaidAccount } from './move-funds.model';
import FlexNumberInput from '../../payments/components/common/flex-number-input';
import { QueryObserverResult } from '@tanstack/react-query';
import { PlaidAccounts } from '@services/flexbase/banking.model';

type Props = {
  form: PaymentForm;
  onBackClick: () => void;
  bankingAccounts: DepositAccount[];
  // when the user wants to add funds from Account Details page
  initDepositAccount?: DepositAccount;
  plaidAccounts: PlaidAccount[];
  onContinue: (values: PaymentForm) => void;
  refetchPlaidAccounts: () => Promise<
    QueryObserverResult<PlaidAccounts, Error>
  >;
};

const TransferDetails = ({
  form,
  onContinue,
  onBackClick,
  plaidAccounts,
  bankingAccounts,
  initDepositAccount,
  refetchPlaidAccounts,
}: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const firstBankingAccount = bankingAccounts[0];

  /**
   * If the user wants to transfer funds to a specific deposit account
   * from the Account Details page, the 'TransferTo' box will be shown
   * with that account. In this case, if there are multiple deposit accounts,
   * the 'transferFrom' box will default to the first one on the list, except
   * in the case where the first deposit account is the same as the 'TransferTo'
   * account. In that case, the 'transferFrom' box will default to the second deposit
   * account on the list. If there is only one deposit account, then the 'transferFrom'
   * box will default to the first Plaid account available.
   */
  const initFromAccount =
    initDepositAccount &&
    initDepositAccount.id === firstBankingAccount.id &&
    bankingAccounts.length > 1
      ? bankingAccounts[1]
      : (form.fromAccount ?? firstBankingAccount);

  const initToAccount =
    initDepositAccount ??
    form.toAccount ??
    bankingAccounts[1] ??
    plaidAccounts[0];

  const transferForm = useForm<PaymentForm>({
    initialValues: {
      ...form,
      fromAccount: initFromAccount,
      toAccount: initToAccount,
      id: '',
    },
    validate: {
      amount: (value) => {
        if (value === 0) {
          return 'Amount is required';
        } else {
          const errorText = getAmountErrorText();

          if (errorText) {
            return errorText;
          } else {
            return null;
          }
        }
      },
    },
  });

  const toAccountSelected = transferForm.values.toAccount as
    | DepositAccount
    | PlaidAccount;
  const isPlaidAccUnlinked =
    toAccountSelected?.plaidOrDeposit === 'plaid' &&
    toAccountSelected?.unlinked;

  const getAmountErrorText = () => {
    if (
      Number(transferForm.values.fromAccount?.available) <
      transferForm.values.amount * 100
    ) {
      return `Your payment amount cannot exceed the available balance in your selected payment account.`;
    }

    return '';
  };

  const selectAccount = (value: AccountProps, accountType: string) => {
    transferForm.setValues({
      ...transferForm.values,
      [`${accountType}Account`]: value,
    });
  };

  const transferFrom = (value: AccountProps) => {
    selectAccount(value, 'from');
  };

  const transferTo = (value: AccountProps) => {
    selectAccount(value, 'to');
  };

  const onContinueClick = () => {
    if (
      transferForm.values.fromAccount?.id === toAccountSelected?.id ||
      isPlaidAccUnlinked
    ) {
      return;
    }
    const validationResult = transferForm.validate();
    if (!validationResult.hasErrors) {
      onContinue(transferForm.values);
    }
  };

  const handleLinkingCompleted = async () => {
    const { data } = await refetchPlaidAccounts();
    const newPlaidAccountsState = data?.accounts ?? [];
    const selectedAccount = newPlaidAccountsState.find(
      (acc) => acc.id === toAccountSelected?.id,
    ) as PlaidAccount;
    // update the form with the new current Plaid account state
    transferForm.setFieldValue('toAccount', {
      ...selectedAccount,
      plaidOrDeposit: 'plaid',
    });
  };

  const handleLinkAccounts = () => {
    navigate('/banking/linked-accounts');
  };

  return (
    <div className={classes.card}>
      <div className="move_funds">
        <span>Transfer between accounts </span>
        <FlexNumberInput
          thousandSeparator
          decimalScale={2}
          allowNegative={false}
          prefix="$"
          placeholder="$0"
          mb={20}
          variant="unstyled"
          data-testid="amount"
          error={transferForm.errors.amount}
          classNames={{ input: classes.amountInput }}
          value={transferForm.values.amount}
          onValueChange={(v) =>
            transferForm.setFieldValue('amount', v.floatValue ?? 0)
          }
        />
        <div className="accounts_select" data-testid="transfer-from">
          <AccountSelection
            label="Transferring from"
            currentAccount={transferForm.values.fromAccount!}
            accounts={bankingAccounts}
            onAccountChange={(c) => {
              transferFrom(c);
            }}
          />
        </div>

        <div className="accounts_select" data-testid="transfer-to">
          <AccountSelection
            label="Transferring to"
            currentAccount={toAccountSelected}
            accounts={[...bankingAccounts, ...plaidAccounts]}
            onAccountChange={(c) => transferTo(c)}
            showAccountFilters
            onLinkingCompleted={handleLinkingCompleted}
          />
        </div>

        {transferForm.values.fromAccount?.id === toAccountSelected?.id && (
          <Alert
            color="red"
            mt="lg"
            icon={<RedAlertIcon />}
            data-testid={'error-message'}
            id={'error-message'}
            withCloseButton={false}
          >
            You can&apos;t send to/from the same account
          </Alert>
        )}
        {(!plaidAccounts.length || isPlaidAccUnlinked) && (
          <Alert
            color="info"
            icon={<InfoIcon />}
            withCloseButton={false}
            mt="lg"
          >
            {isPlaidAccUnlinked ? (
              <Text>Re-link your account to continue with the transfer.</Text>
            ) : (
              <Text>
                If you&apos;d like to transfer funds from an external account,
                please make sure to{' '}
                <Anchor color="promote.3" onClick={handleLinkAccounts}>
                  link your account(s).
                </Anchor>
              </Text>
            )}
          </Alert>
        )}
        <Group position="apart" mt={30}>
          <Button
            variant="outline"
            onClick={onBackClick}
            data-testid={'button-back'}
          >
            Back
          </Button>
          <Button
            variant="light"
            onClick={onContinueClick}
            data-testid={'button-next'}
          >
            Continue
          </Button>
        </Group>
      </div>
    </div>
  );
};

export default TransferDetails;
