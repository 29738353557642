import {
  Box,
  Button,
  Center,
  Loader,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { PersonPrefillResponse } from '../../../../services/platform/models/identity.model';
import getPaddedAccountMask from '../../../../utilities/formatters/get-padded-account-mask';
import { getVerifyIdentityAccordionListItems } from '../verify-identity/get-verify-identity-accordion-list-items';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  RegistrationProductsState,
} from '../../../../states/application/product-onboarding';
import { CloseIcon, EditIcon3 } from '../../../../assets/svg';
import { useOnboardingStyles } from '../../onboarding.styles';
import { formatPersonalTaxId } from '../../../../utilities/formatters/format-tax-id';
import GooglePlacesSuggest from '@common/input/google-places-suggest-input';
import FlexbaseInput from '@common/input/flexbase-input';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import { US_STATES } from '../../../../states/business/constants';
import { formatZip } from '../../../../utilities/formatters/format-address';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '../../../../utilities/validators/validate-required';
import validatePostalCode from '../../../../utilities/validators/validate-postal-code';
import { validatePersonalTaxId } from '../../../../utilities/validators/validate-tax-id';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import YellowBanner from '../../components/yellow-banner';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';
import { usePlatformPrefillContext } from '../../../../providers/platform-prefill.context';
import {
  useCreatePlatformAddressMutation,
  useUpdatePlatformPersonMutation,
} from '../../../../queries/use-get-me';

export const PrefillPersonStep = () => {
  const { classes } = useOnboardingStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const { userIsApplicant, personId, accountId } =
    useRecoilValue(ApplicationState);
  const products = useRecoilValue(RegistrationProductsState);
  const accordionList = getVerifyIdentityAccordionListItems(
    userIsApplicant,
    products,
  );
  const { mutateAsync: updatePlatformPerson } =
    useUpdatePlatformPersonMutation();
  const { mutateAsync: createPlatformAddress } =
    useCreatePlatformAddressMutation();
  const { setState, goToNextStep } = usePrefillMultistepFormContext();

  const {
    personPrefillData: prefillResult,
    personPrefillLoading: loadingPrefill,
    personPrefillError: isError,
  } = usePlatformPrefillContext();

  const theForm = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      taxId: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    validate: {
      line1: RequiredFieldValidator('Street address is required'),
      state: RequiredFieldValidator('State is required'),
      postalCode: (value) =>
        validatePostalCode(value)
          ? null
          : 'A valid postal code is required (ex 30044 or 30044-4576)',
      city: RequiredFieldValidator('City is required'),
      taxId: ConditionalFieldValidator(
        showEditForm || !prefillResult?.prefilledValues.taxId,
        (val) => (validatePersonalTaxId(val) ? null : 'Invalid SSN or ITIN'),
      ),
      firstName: RequiredFieldValidator(),
      lastName: RequiredFieldValidator(),
    },
  });

  const setFormValues = (_prefillResult?: PersonPrefillResponse) => {
    theForm.setValues({
      firstName: _prefillResult?.prefilledValues.givenName ?? '',
      lastName: _prefillResult?.prefilledValues.familyName ?? '',
      taxId: '',
    });
    if (_prefillResult?.addressSelection.length) {
      const firstAddress = _prefillResult.addressSelection[0];
      theForm.setValues({
        line1: firstAddress.street[0],
        line2: firstAddress.street[1] ?? '',
        city: firstAddress.locality,
        state: firstAddress.region,
        postalCode: firstAddress.postalCode,
      });
    }
  };

  useEffect(() => {
    setFormValues(prefillResult);
  }, [prefillResult]);

  const handleTaxIdChange = (value: string) =>
    theForm.setFieldValue('taxId', formatPersonalTaxId(value));

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    theForm.setFieldValue('line1', item.address.trim());
    theForm.setFieldValue('city', item.city);
    theForm.setFieldValue('state', item.state);
    theForm.setFieldValue('postalCode', item.postalCode.trim());
  };

  const setZip = (zip: string) => {
    theForm.setFieldValue('postalCode', formatZip(zip));
  };

  const showFormInputs = showEditForm || !prefillResult?.prefilled || isError;

  const handleFormSubmit = async () => {
    const validationResult = theForm.validate();
    if (!validationResult.hasErrors) {
      try {
        setState({ loading: true, error: null });
        const values = theForm.values;
        if (showFormInputs) {
          await updatePlatformPerson({
            accountId,
            personId,
            update: {
              taxId: values.taxId,
              givenName: values.firstName,
              familyName: values.lastName,
            },
          });
        }
        await createPlatformAddress({
          accountId,
          personId,
          body: {
            isPrimary: true,
            active: true,
            street: [values.line1, values.line2],
            postalCode: values.postalCode,
            locality: values.city,
            region: values.state,
            country: 'US',
            type: 'home',
          },
        });
        await flexbaseOnboardingClient.updateUser({
          id: personId,
          ficoPullSigned: true,
          patriotActSigned: true,
        });
        goToNextStep({ prefilled: true });
      } catch {
        setState({
          error:
            'We encountered an error while updating your identity information',
        });
      } finally {
        setState({ loading: false });
      }
    }
  };

  const handleEditClick = () => {
    if (showEditForm) {
      setFormValues(prefillResult);
      setShowEditForm(false);
    } else {
      setShowEditForm(true);
    }
  };

  if (!prefillResult && loadingPrefill) {
    return (
      <Center h="5rem">
        <Loader variant="dots" />
      </Center>
    );
  }

  return (
    <MultiStepFormStepWrapper id="identity" onFormSubmit={handleFormSubmit}>
      <Text
        fz={14}
        c="primarySecondarySuccess.8"
        fw={500}
        data-testid="person-prefill-description"
      >
        {prefillResult?.prefilled
          ? 'We were able to prefill some of your information. Please verify for accuracy and update if needed. We are required by law to collect this information.'
          : 'We are required by law to collect this information.'}
      </Text>
      <Stack mt="xl" mb="xl">
        {accordionList.map((item) => {
          return (
            <Box key={item.label} className={classes.prefillAccordionListItem}>
              <Text ff="redaction">{item.description}</Text>
            </Box>
          );
        })}
      </Stack>
      <Box className={classes.prefillPersonInputContainer}>
        {prefillResult?.prefilled && (
          <Box className={classes.prefillPersonEditToggle}>
            <Button
              variant="outline"
              size="xs"
              onClick={handleEditClick}
              rightIcon={
                showEditForm ? (
                  <CloseIcon className={classes.prefillPersonEditIcon} />
                ) : (
                  <EditIcon3 className={classes.prefillPersonEditIcon} />
                )
              }
            >
              {showEditForm ? 'Cancel' : 'Edit Information'}
            </Button>
          </Box>
        )}
        <Box>
          <Stack>
            <Box className={classes.containerOwnerForm}>
              <TextInput
                className={classes.btnSpacingAddressPage}
                placeholder="Legal first name"
                label="First name"
                {...theForm.getInputProps('firstName')}
                data-sardine-id="input-first-name"
                disabled={!showFormInputs}
              />
              <TextInput
                className={classes.btnSpacingAddressPage}
                placeholder="Legal last name"
                label="Last name"
                {...theForm.getInputProps('lastName')}
                data-sardine-id="input-last-name"
                disabled={!showFormInputs}
              />
            </Box>
            <TextInput
              placeholder="Social Security # or ITIN"
              data-sardine-id="input-ssn"
              inputMode="numeric"
              label="SSN or ITIN"
              {...(showFormInputs
                ? theForm.getInputProps('taxId')
                : {
                    disabled: true,
                    value: getPaddedAccountMask(
                      prefillResult.prefilledValues.taxId.slice(-4),
                      5,
                    ),
                  })}
              onChange={(e) => handleTaxIdChange(e.target.value)}
            />

            <Box className={classes.containerSearchAddress}>
              <GooglePlacesSuggest
                label="Address line 1"
                {...theForm.getInputProps('line1')}
                onChange={(value) => {
                  theForm.setFieldValue('line1', value);
                }}
                onItemSubmit={selectAddress}
                placeholder="Address line 1"
                classNames={{
                  label: classes.inputLabel,
                  root: classes.btnSpacingAddressPage,
                  container: classes.btnSpacingAddressPage,
                }}
                data-sardine-id="input-address-street"
                id="input-address-street"
                disabled={!showFormInputs}
              />
              <FlexbaseInput
                label="Address line 2"
                placeholder="Address line 2"
                {...theForm.getInputProps('line2')}
                classNames={{
                  label: classes.inputLabel,
                  root: classes.btnSpacingAddressPage,
                }}
                data-sardine-id="input-address-line-2"
                id="input-address-line-2"
                disabled={!showFormInputs}
              />
            </Box>
            <Box className={classes.containerSearchAddress}>
              <FlexbaseInput
                label="City"
                placeholder="City"
                {...theForm.getInputProps('city')}
                classNames={{
                  label: classes.inputLabel,
                  root: classes.btnSpacingAddressPage,
                }}
                data-sardine-id="input-address-city"
                id="input-address-city"
                disabled={!showFormInputs}
              />

              <Box className={classes.stateZipContainer}>
                <FlexbaseSelect
                  {...theForm.getInputProps('state')}
                  label="State"
                  placeholder="State"
                  data={(US_STATES || []).map(
                    (s: { label: string; value: string }) => ({
                      label: s.value,
                      value: s.value,
                      name: s.label,
                    }),
                  )}
                  searchable
                  dropdownPosition="bottom"
                  maxDropdownHeight={400}
                  nothingFound="No data"
                  filter={(value, item) => {
                    const lowerCaseValue = value.toLowerCase();
                    return (
                      item.label?.toLowerCase().includes(lowerCaseValue) ||
                      item.value.toLowerCase().includes(lowerCaseValue) ||
                      item.name?.toLowerCase().includes(lowerCaseValue)
                    );
                  }}
                  classNames={{
                    label: classes.inputLabel,
                    root: classes.btnSpacingAddressPage,
                  }}
                  data-sardine-id="input-address-select-state"
                  id="input-address-select-state"
                  disabled={!showFormInputs}
                />
                <TextInput
                  label="ZIP Code"
                  data-sardine-id="input-address-zip-code"
                  placeholder="ZIP"
                  {...theForm.getInputProps('postalCode')}
                  classNames={{
                    label: classes.inputLabel,
                    root: classes.btnSpacingAddressPage,
                  }}
                  onChange={(e) => setZip(e.target.value)}
                  id="input-address-zip-code"
                  disabled={!showFormInputs}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>

      {userIsApplicant && products.includes('CREDIT') && (
        <YellowBanner text="If you currently have a freeze on your credit, you must remove the Equifax freeze before submitting your application. We will not be able to process your application until the Equifax freeze is removed." />
      )}
    </MultiStepFormStepWrapper>
  );
};
