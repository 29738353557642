import { useForm } from '@mantine/form';
import { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import {
  Alert,
  Box,
  Button,
  Group,
  createStyles,
  useMantineTheme,
} from '@mantine/core';

import Multiselect from './custom-multiselect';
import Input from 'components/input/flexbase-input';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { notifications } from '@mantine/notifications';
import {
  useCardCategory,
  useUpdateCardCategory,
  useUpdateCreditCardMutation,
} from '@queries/use-credit-cards';
import { CreditCard } from '@services/flexbase/card.model';
import { LIMIT_INTERVALS } from '@utilities/content/limit-intervals';
import FlexNumberInput from '../../../payments/components/common/flex-number-input';
import { useCreditBalance } from '@queries/use-credit-balance';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from '../../../onboarding/onboarding-form.state';
import FlexbaseSelect from 'components/select/flexbase-select';
import { CalendarIcon } from '../../../../assets/svg';
import { DateTime } from 'luxon';
import { DatePickerInput } from '@mantine/dates';
import CardCategorySelect from '@common/select/card-category-select';
import { SpendPlanView } from '@flexbase-eng/types/dist/accounting';
import { Group as CardInfoGroup } from 'states/cards/card-info';

type Props = {
  card: CreditCard;
  onCancelClick: () => void;
  onCardUpdated: (updatedCard: CreditCard) => void;
  shouldRenderCategory: boolean;
  connectionId: string;
  spendPlan: SpendPlanView | undefined;
  formatFrequency: (frequency: string) => string;
};

const EditCard = ({
  card,
  onCancelClick,
  shouldRenderCategory,
  connectionId,
  onCardUpdated,
  spendPlan,
  formatFrequency,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const companyId = useRecoilValue(CompanyIdState);
  const { data } = useCreditBalance(companyId);
  const [errorMessage, setErrorMessage] = useState('');
  const [terminateDate, setTerminateDate] = useState<Date | null>(
    card?.terminateAt
      ? DateTime.fromFormat(card?.terminateAt, 'yyyy-MM-dd').toJSDate()
      : null,
  );

  const updateMutation = useUpdateCreditCardMutation();
  const { mutate } = useUpdateCardCategory();

  const { data: cardCategory } = useCardCategory({
    connectionId,
    cardId: card.id,
  });

  const form = useForm({
    initialValues: {
      cardName: card?.cardName ?? '',
      amount: card?.expensesTypes?.amount ?? 0,
      groups: spendPlan?.id
        ? ((spendPlan?.allowedMccs?.length > 0
            ? spendPlan.allowedMccs
            : spendPlan?.blockedMccs) as CardInfoGroup[])
        : (card?.expensesTypes?.groups ?? []),
      interval: card?.expensesTypes?.interval ?? 'unlimited',
      categoryId: cardCategory?.card?.category.id ?? '',
    },
    validate: {
      amount: (v, values) => {
        if (values.interval === 'unlimited') {
          return null;
        }
        if (data?.creditLimit && v > data.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            data.creditLimit,
          )}`;
        }
      },
    },
  });

  const updateCard = () => {
    const validation = form.validate();
    if (!validation.hasErrors) {
      updateMutation.mutate(
        {
          id: card.id,
          card: {
            cardName: form.values.cardName,
            expensesTypes: {
              amount:
                form.values.interval === 'unlimited'
                  ? 0
                  : Number(form.values.amount),
              groups: form.values.groups,
              interval:
                form.values.interval === 'unlimited'
                  ? null
                  : form.values.interval,
            },
            notifyUse: card.notifyUse,
            terminateAt:
              terminateDate !== null
                ? DateTime.fromJSDate(terminateDate).toFormat('yyyy-MM-dd')
                : null,
          },
        },
        {
          onSuccess: (cardUpdated) => {
            if (form.values.categoryId && shouldRenderCategory) {
              mutate({
                cardId: cardUpdated.card.id,
                categoryId: form.values.categoryId,
                connectionId,
              });
            }
            onCardUpdated(cardUpdated.card);
            notifications.show({
              title: 'Updated',
              message: 'This card was successfully updated',
            });
          },
          onError: () => {
            setErrorMessage(
              `An error occurred while trying to update card with name ${card.cardName}`,
            );
          },
        },
      );
    }
  };

  const handleOnchangeCategory = (value: string) => {
    form.setFieldValue('categoryId', value);
  };

  return (
    <Box w="100%">
      <Input
        label="Name of card"
        value={form.values.cardName}
        onChange={(e) => {
          form.setFieldValue('cardName', e.target.value);
        }}
      />
      <Box mt="md">
        <Multiselect form={form} disabled={!!spendPlan} />
      </Box>
      {spendPlan ? (
        <>
          <Input label="Spend plan" value={spendPlan?.name} disabled mt="md" />
          <Input
            label="Limit type"
            value={formatFrequency(spendPlan.frequency)}
            disabled
            mt="md"
          />
        </>
      ) : (
        <FlexbaseSelect
          label="Limit type"
          value={form.values.interval}
          placeholder="Select Limit Type"
          data={LIMIT_INTERVALS}
          onChange={(value) => {
            form.setFieldValue('interval', value!);
          }}
          mt="md"
        />
      )}

      {form.values.interval !== 'unlimited' && !spendPlan && (
        <FlexNumberInput
          style={{ marginTop: `${theme.spacing.md}` }}
          mt="0.4rem"
          variant="default"
          label="Spending limit"
          radius={8}
          labelProps={{
            color: theme.colors.blackish[1],
          }}
          icon={<>$</>}
          pattern="[0-9]*"
          data-testid="amount"
          thousandSeparator=","
          styles={{
            label: { color: 'black' },
            input: {
              '&:focus': { borderColor: theme.fn.primaryColor() },
            },
          }}
          onValueChange={(value) => {
            form.setFieldValue('amount', value.floatValue ?? 0);
          }}
          value={form.values.amount}
          error={form.errors.amount}
        />
      )}
      {shouldRenderCategory && (
        <CardCategorySelect
          classNames={{
            label: classes.cardCategoryLabel,
            input: classes.cardCategory,
          }}
          value={form.values.categoryId}
          onChange={handleOnchangeCategory}
        />
      )}
      <DatePickerInput
        icon={
          <CalendarIcon
            height="20px"
            width="20"
            color={theme.colors.blackish[0]}
          />
        }
        mt="md"
        minDate={DateTime.fromJSDate(new Date()).plus({ days: 1 }).toJSDate()}
        clearable={true}
        valueFormat="YYYY-MM-DD"
        label="Termination date"
        value={terminateDate}
        onChange={(d) => {
          setTerminateDate(d);
        }}
      />

      {errorMessage && (
        <Box mt="lg">
          <Alert
            icon={<FaExclamationCircle color="red" />}
            color="red"
            withCloseButton
            variant="light"
            onClose={() => setErrorMessage('')}
            styles={() => ({
              closeButton: {
                margin: `auto ${theme.spacing.xxs} auto ${theme.spacing.xs}`,
              },
              icon: { margin: `auto ${theme.spacing.xs} auto 0` },
              message: {
                margin: `auto ${theme.spacing.xs} auto ${theme.spacing.xxs}`,
                color: theme.colors.blackish[1],
              },
            })}
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      <Group mt="md" sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="outline"
          onClick={() => onCancelClick()}
          bg="neutral.0"
        >
          Back
        </Button>
        <Button loading={updateMutation.isPending} onClick={updateCard}>
          Save
        </Button>
      </Group>
    </Box>
  );
};

export default EditCard;

export const useStyles = createStyles(() => ({
  cardCategoryLabel: {
    marginTop: '1rem',
  },
  cardCategory: {
    margin: '0.2rem 0 !important',
  },
}));
