import { createStyles } from '@mantine/core';
import { AuthLoader as Loader } from '../../assets/svg';
import { ReactNode } from 'react';

type ClassNames = { loadingContainer: string; svg: string };

type Props = {
  backgroundColor: string;
  loaderColor: string;
  classNames: Partial<ClassNames>;
  label: ReactNode;
};

const AuthLoader = (props?: Partial<Props>) => {
  const { classes, cx } = useStyles({
    loaderColor: props?.loaderColor,
    backgroundColor: props?.backgroundColor,
  });

  return (
    <div
      className={cx(
        classes.loadingContainer,
        props?.classNames?.loadingContainer,
      )}
    >
      <div className={cx(classes.svg, props?.classNames?.svg)}>
        <Loader />
      </div>
      {props?.label && props.label}
    </div>
  );
};

export default AuthLoader;

type StyleArgs = { loaderColor: string; backgroundColor: string };

const useStyles = createStyles((theme, args: Partial<StyleArgs>) => ({
  loadingContainer: {
    zIndex: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: args.loaderColor || theme.fn.themeColor('neutral', 0),
    backgroundColor: args.backgroundColor || 'rgba(7,28,20, 0.8)',
  },
  svg: {
    svg: {
      animation: 'rotation 2s infinite linear',
    },
    '@keyframes rotation': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(359deg)',
      },
    },
  },
}));
