/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
// Progress
import { Text, createStyles } from '@mantine/core';
import { formatCurrency } from '../../../../utilities/formatters/format-currency';

type Props = {
  title: ReactNode;
  totalAvailableBalance: number;
  currentAmount: number;
  currentAmountPercent?: boolean;
  classNames?: Partial<{ card: string; endContent: string }>;
  children?: ReactNode;
};

const ProgressPercent = ({
  title,
  totalAvailableBalance,
  currentAmountPercent,
  classNames,
  currentAmount,
  children,
}: Props) => {
  const { classes, cx } = useStyles();
  const amountPercent =
    totalAvailableBalance > 0
      ? (currentAmount / totalAvailableBalance) * 100
      : 0;

  return (
    <div className={cx(classes.card, classNames?.card)}>
      <Text size={16} color="#5F5F5F" weight={500}>
        {title}
      </Text>
      <Text size={26} color="#000000" weight={500} className={classes.pp}>
        {formatCurrency(currentAmount)}
        {currentAmountPercent && (
          <>
            {' / '}
            {amountPercent.toFixed(2)}%
          </>
        )}
      </Text>
      {children}
    </div>
  );
};

export default ProgressPercent;

const useStyles = createStyles((theme) => ({
  card: {
    width: 'auto',
    height: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  endContent: {
    marginTop: 'auto',
  },
  flexGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacingTop: {
    marginTop: '16px',
  },
}));
