import { createStyles } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import RecoilNexus from 'utilities/recoil/nexus';
import { getEnvironment } from './utilities/url/window-helpers';
import FlexbaseRoutes from './routes';
import ZenDesk from 'components/zendesk/zendesk';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { FacebookPixel } from './services/analytics/pixel';
import Loadable from './components/loading/loadable';
import TagManager from 'react-gtm-module';
import { AuthProvider } from './states/auth/auth-token';
import { RouteSectionProvider } from './components/routes/route-context';
import QueryProvider from 'providers/query-provider';
import { SplitProvider } from 'providers/split.provider';
import { BackgroundProvider } from './providers/background-provider';
import { MantineProviders } from 'providers/mantine-providers';
import { SardineProvider } from '@sardine-ai/react-js-wrapper';
// import { init } from '@fullstory/browser';

const useStyles = createStyles(() => ({
  fullheight: {
    height: '100vh',
  },
}));

// https://github.com/segmentio/analytics-next/tree/master/packages/browser
export const analytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.VITE_APP_SEGMENT_KEY || '',
  },
  {
    integrations: {
      'Segment.io': {
        apiHost:
          import.meta.env.VITE_APP_ANALYTICS_PROXY ||
          'https://proxy-dev.flexbase.app/v1',
        protocol: 'https',
      },
    },
    user: {
      persist: false,
    },
  },
);

function App() {
  const hostEnv = getEnvironment(window.location.host);
  FacebookPixel.init();

  if (hostEnv === 'production' || hostEnv === 'development') {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_KEY,
      release: import.meta.env.VITE_APP_GITHUB_SHA,
      replaysSessionSampleRate: hostEnv === 'production' ? 0.3 : 0.0,
      replaysOnErrorSampleRate: hostEnv === 'production' ? 1.0 : 0.0,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: hostEnv === 'production' ? 0.3 : 1.0,
      environment: hostEnv,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        "Can't find variable: _AutofillCallbackHandler",
      ],
    });
  }

  if (hostEnv === 'development' || hostEnv === 'local') {
    TagManager.initialize({
      gtmId: 'GTM-WFHSQ3V',
    });
  }

  if (hostEnv === 'production') {
    TagManager.initialize({
      gtmId: 'GTM-MTJK9FZ',
    });
    // init({ orgId: 'o-1ZG1VB-na1' });
  }

  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {hostEnv === 'production' && <ZenDesk />}
      <div className={classes.fullheight} id="app-body">
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="r9grrg8jfhkdxbdbo6zaifyynbfluf"
          />
          <script
            type="text/javascript"
            src="https://js.verygoodvault.com/vgs-show/2.1.0/show.js"
          ></script>
        </Helmet>
        <SardineProvider
          clientId={import.meta.env.VITE_APP_SARDINE_KEY}
          environment={hostEnv === 'production' ? 'production' : 'sandbox'}
        >
          <QueryProvider>
            <RecoilRoot>
              <RecoilNexus />
              <SplitProvider>
                <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
                  <MantineProviders>
                    <AuthProvider>
                      <Loadable>
                        <RouteSectionProvider>
                          <BackgroundProvider>
                            <FlexbaseRoutes />
                          </BackgroundProvider>
                        </RouteSectionProvider>
                      </Loadable>
                    </AuthProvider>
                  </MantineProviders>
                </BrowserRouter>
              </SplitProvider>
            </RecoilRoot>
          </QueryProvider>
        </SardineProvider>
      </div>
    </>
  );
}

export default App;
