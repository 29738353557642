import { useRecoilState, useSetRecoilState } from 'recoil';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from '../../services/flexbase-client';
import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { UserCompanyState } from '../../states/company/company';
import { UserInfoState } from '../../states/user/user-info';
import { formatInitials } from '../../utilities/formatters/format-strings';
import { getMainOwner } from '../../areas/onboarding/onboarding-form.state';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import useModal from 'components/modal/modal-hook';
import { useShowTermsModal } from 'components/terms/terms-modal';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from '../../states/application/product-onboarding';
import { useHasTerms } from '../terms/use-has-terms';
import { refreshAndStoreTokenFromUser } from '../../utilities/auth/refresh-token';
import { useAuthToken } from '../../states/auth/auth-token';

const IDLE_MODAL_ID = 'idle-detection-modal';

const OnboardedRoutes = () => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(true);
  const [status, setOverviewState] = useRecoilState(ApplicationState);
  const { openConfirmationModal, closeAllModals } = useModal();
  const { hasTermsForActiveProduct } = useHasTerms();
  const showTermsModal = useShowTermsModal();
  const { logout } = useAuthToken();

  const onUserIdle = async () => {
    await logout();
    closeAllModals();
  };

  useIdleTimer({
    onPrompt: (_, idleTimer) => {
      openConfirmationModal({
        id: IDLE_MODAL_ID,
        title: 'User idle detection',
        confirmText: 'Logout',
        cancelText: 'Keep me logged in',
        onCancel: () => {
          handleIdleCancel(idleTimer);
        },
        onConfirm: () => {
          onUserIdle();
        },
        showCloseButton: false,
        content: 'You are about to be logged out due to inactivity.',
      });
    },
    onIdle: onUserIdle,
    timeout: 15 * 60 * 1000,
    promptBeforeIdle: 2 * 60 * 1000, // Show the idle timeout 2 minutes before idle/initial token exp to make sure token is refreshed in time
  });

  const handleIdleCancel = async (idleTimer?: IIdleTimer) => {
    idleTimer?.reset();
    await refreshAndStoreTokenFromUser(true);
  };

  const setUserInfo = useSetRecoilState(UserInfoState);
  const setUserCompany = useSetRecoilState(UserCompanyState);

  const setOnboardingStatus = async () => {
    const _status = status.user.id
      ? status
      : await getProductOnboardingStatus();
    if (!status.user.id) {
      setOverviewState(_status);
    }

    const { company, user } = _status;
    let hasProfileDoc = false;
    try {
      const preferences = await flexbaseOnboardingClient.getUserPreference();
      hasProfileDoc = !!preferences.profileDocId;
    } catch (e) {
      console.error('Error fetching user preferences.');
    }

    let blobURL = null;

    if (hasProfileDoc) {
      const response = await flexbaseClient.getPersonPicture(user.id);
      if (response) {
        blobURL = URL.createObjectURL(new Blob([response]));
      }
    }

    setUserInfo({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: formatInitials(`${user.firstName} ${user.lastName}`),
      phone: user.phone,
      picUrl: blobURL,
      cellPhone: user.cellPhone,
      address: {
        line1: user.address.line1,
        line2: user.address.line2,
        city: user.address.city,
        state: user.address.state,
        postalCode: user.address.postalCode,
        country: user.address.country,
      },
      jobTitle: user.jobTitle,
      email: user.email,
      roles: [...user.roles],
      promoCode: user.promoCode,
    });

    setUserCompany({
      id: company.id || '',
      creditLimit: company.creditLimit ? company.creditLimit : '0',
      name: company.companyName || '',
      initials: formatInitials(company.companyName),
      autopay: company.autopay,
      address: company.address?.line1 || '',
      addressLine2: company.address?.line2 || '',
      city: company.address?.city || '',
      postalCode: company.address?.postalCode || '',
      state: company.address?.state || '',
      country: company.address?.country || '',
      owners: company.owners?.map((o) => ({
        id: o.id || '',
        title: o.jobTitle || '',
        percent: o.ownershipPct || 0,
        firstName: o.firstName || '',
        lastName: o.lastName || '',
        email: o.email || '',
      })),
      mainOwner: getMainOwner(company, user),
      ein: company.taxId || '',
      websiteUrl: company.website || '',
      optedProducts: company.optedProducts ? [...company.optedProducts] : [],
      phone: company.phone,
    });
  };

  const checkStatus = async () => {
    try {
      await setOnboardingStatus();
      setLoading(false);
    } catch (e) {
      console.error('onboarded-routes.tsx', e);
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    if (hasTermsForActiveProduct) {
      showTermsModal();
    }
  }, [hasTermsForActiveProduct]);

  return (
    <>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ color: theme.fn.primaryColor() }}
      />
      {!loading && <Outlet />}
    </>
  );
};

export default OnboardedRoutes;
