import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Box, Divider, Flex, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from './navbar.styles';
import {
  BOTTOM_NAVBAR_OPTIONS,
  MenuOption,
  SubMenuOption,
  useTopNavbarOptions,
} from './menu-options';
import { getEnvironment } from '../../utilities/url/window-helpers';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
  RolesSelector,
} from '../../states/application/product-onboarding';
import { UserRole } from '../../states/user/user-info';
import CompaniesDropdown from './multi-account/companies-dropdown';
import { useDisclosure } from '@mantine/hooks';

type Props = {
  navbarOpen: boolean;
  setNavbarOpened: Dispatch<SetStateAction<boolean>>;
};

/**
 *
 * @param userRoles the currently logged in persons roles
 * @param allowedRoles the allowed roles for the navtab
 * @returns
 */
const allowed = (userRoles: UserRole[], allowedRoles?: UserRole[]): boolean => {
  return (
    !allowedRoles?.length || userRoles.some((r) => allowedRoles.includes(r))
  );
};

const NavbarMobile = ({ setNavbarOpened, navbarOpen }: Props): ReactElement => {
  const { classes } = useStyles({ navbarOpen });
  const [highlightedRoute, setHighlightedRoute] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const hostEnv = getEnvironment(window.location.host);
  const isAdmin = useRecoilValue(IsAdmin);
  const { optedProducts, excludedProducts } = useRecoilValue(ApplicationState);
  const roles = useRecoilValue(RolesSelector);

  const topOptions = useTopNavbarOptions();

  const [opened, { toggle }] = useDisclosure(false);

  const path = location.pathname.split('/').filter((p) => p);

  const menuItemClass = (opt: MenuOption): string => {
    if (
      location.pathname === opt.location ||
      opt.location.includes(path[0]) ||
      highlightedRoute === opt.label
    ) {
      if (opt.unclickable) {
        return classes.notClickableHighlightedMenuOption;
      }
      return classes.highlightedMenuOption;
    } else if (opt.disabled || (opt.adminOnly && !isAdmin)) {
      return classes.disabledMenuOption;
    }
    if (opt.unclickable) {
      return classes.notClickableMenuOption;
    }
    return classes.menuOption;
  };

  const subMenuItemClass = (opt: SubMenuOption): string => {
    if (opt.location.includes(path[0])) {
      return classes.highlightedMenuSuboption;
    } else if (opt.disabled) {
      return classes.disabledMenuOption;
    }
    return classes.menuSuboption;
  };

  const openSubOptions = (opt: MenuOption) => {
    if (!opt.subRoutes) {
      setNavbarOpened(false);
      navigate(opt.location);
    }
    setHighlightedRoute(opt.label);
  };

  const generateNavbarOptions = (options: MenuOption[]) => {
    const crossSellProducts = ['CREDIT', 'BANKING'];

    return options
      .filter((opt) => {
        // filter out navtabs that the user role does not have access to
        return allowed(roles, opt.rolesAllowed);
      })
      .filter((menuOpt) => {
        // See navbar.tsx for explanation. Need to move all of this logic to a hook so it isn't duped
        if (menuOpt.product === null) {
          return true;
        }

        if (excludedProducts.includes(menuOpt.product)) {
          return false;
        }

        return (
          optedProducts.includes(menuOpt.product) ||
          crossSellProducts.includes(menuOpt.product)
        );
      })
      .map((opt: MenuOption) => {
        if (opt.disabled === true) {
          return;
        }
        if (opt.devOnly === true && hostEnv === 'production') {
          return;
        } else {
          return (
            <div key={opt.label}>
              <div
                className={`${classes.menuOptionCommon} ${menuItemClass(opt)}`}
                onClick={() => {
                  openSubOptions(opt);
                }}
              >
                <IconContext.Provider value={{ size: '25px' }}>
                  <div className={classes.menuOptionIcon}>{opt.icon}</div>
                </IconContext.Provider>
                <Text
                  size="sm"
                  style={{
                    fontFamily: 'PP Neue Montreal',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                    marginLeft: '30px',
                    alignItems: 'center',
                  }}
                >
                  {opt.label}
                  {['Insurance'].includes(opt.label) && (
                    <span className={classes.txtComingSoon}>Coming Soon</span>
                  )}
                  {opt.product !== null &&
                    !optedProducts.includes(opt.product) &&
                    crossSellProducts.includes(opt.product) && (
                      <span className={classes.txtComingSoon}>Apply Now</span>
                    )}
                </Text>
              </div>
              {opt?.subRoutes && opt?.subRoutes.length > 0 && (
                <Flex ml={60}>
                  <Divider
                    mr="sm"
                    color="primarySecondarySuccess.5"
                    orientation="vertical"
                  />
                  <Box w="100%">
                    {opt.subRoutes?.map((route) => {
                      return (
                        <Text
                          key={route.label}
                          size="md"
                          mt="md"
                          px="sm"
                          py="xs"
                          c="neutral.0"
                          className={subMenuItemClass(route)}
                          onClick={() => {
                            setNavbarOpened(false);
                            navigate(`${route.location}`);
                            setHighlightedRoute(opt.label);
                          }}
                        >
                          {route.label}
                        </Text>
                      );
                    })}
                  </Box>
                </Flex>
              )}
            </div>
          );
        }
      });
  };

  return (
    <div className={classes.fullScreen}>
      <div className={classes.navbar}>
        <CompaniesDropdown {...{ opened, toggle }} />
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(topOptions)}
        </div>
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(BOTTOM_NAVBAR_OPTIONS)}
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
