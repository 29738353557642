import OnboardingStep from '../components/onboarding-step';
import { MultiSelect } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import { useState } from 'react';
import { flexbaseOnboardingClient } from '../../../services/flexbase-client';
import { useApplicationFlowContext } from '../onboarding-hooks';
import {
  countrySelectOptions,
  internationalWireDisclaimer,
} from '../../payments/components/send-payment/international-payments/util';

type IntendedCountries = {
  intendedCountries: string[];
};

export const IntendedCountries = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { company } = useRecoilValue(ApplicationState);
  const { navigateToNextProductStep, goBack } = useApplicationFlowContext();

  const countryData = countrySelectOptions;

  const form = useForm<IntendedCountries>({
    initialValues: {
      intendedCountries: [],
    },
    validate: {
      intendedCountries: (value) =>
        value.length === 0
          ? 'Please provide at least one country you intend on sending payments to'
          : null,
    },
  });

  const handleNextClick = async () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        await flexbaseOnboardingClient.updateCompany({
          id: company.id,
          intendedCountries: form.values.intendedCountries,
        });
        navigateToNextProductStep();
      } catch (e) {
        setError('Unable to update purpose of account');
        console.error(e);
      } finally {
        setLoading(true);
      }
    }
  };

  return (
    <OnboardingStep
      title={'Purpose and Nature of Account'}
      subtitle="Select the countries you intend to make international payments to."
      stepId="purpose-of-account"
      showContinueSpinner={loading}
      error={error}
      onBackClick={goBack}
      onNextClick={handleNextClick}
      bottomDisclosureText={internationalWireDisclaimer}
    >
      <MultiSelect
        label="Select intended payment countries"
        data={countryData}
        searchable
        clearable
        {...form.getInputProps('intendedCountries')}
        onChange={(val) => {
          form.setFieldValue('intendedCountries', val);
        }}
      />
    </OnboardingStep>
  );
};
