import OnboardingStep from '../../components/onboarding-step';
import { TextInput, Text, Grid } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../states/application/product-onboarding';
import { RequiredFieldValidator } from '../../../../utilities/validators/validate-required';
import { useReducer, useState } from 'react';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import { PDF_MIME_TYPE } from '@mantine/dropzone';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { getPdfThumbnailUrlFromUrl } from '../../../../utilities/pdf/thumbnail';
import { internationalWireDisclaimer } from '../../../payments/components/send-payment/international-payments/util';
import { CompanyDocument } from '../../../../services/flexbase/flexbase-onboarding-client';
import { FlexbaseTable } from '../../../../components/table';
import { TableColumn } from 'react-data-table-component';
import { useCompanyDocuments } from '../../../../queries/use-company-documents';
import { DropzoneSection, FilePreviewSection } from './files-section';
import { useGetUsers } from '../../../../queries/use-users';

type AdditionalIdentityForm = {
  type: string;
  number: string;
  frontFile: File | null;
  backFile?: File | null;
};

export type FileProps = {
  url: string;
  name: string;
  type: 'front' | 'back';
};

type UserName = {
  userName?: string;
};

type FileAction =
  | { type: 'ADD_FILE'; file: FileProps }
  | { type: 'REMOVE_FILE'; file: FileProps }
  | { type: 'RESET_FILES' };

const ALLOWED_DOCS = ['Passport', "Driver's License", 'Government ID'];

const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB in bytes

export const AdditionalIdentity = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: existingDocs } = useCompanyDocuments();
  const { user, requiredProperties } = useRecoilValue(ApplicationState);
  const { navigateToNextProductStep, goBack } = useApplicationFlowContext();
  const { data: users } = useGetUsers();

  const existingDocsTableColumns: TableColumn<CompanyDocument & UserName>[] = [
    {
      name: 'hidden_date',
      selector: (row) => row.uploadedAt,
      omit: true,
      id: 'hidden_date',
    },
    {
      name: 'User',
      selector: (row) => row.userName || row.userId,
      sortable: true,
    },
    {
      name: 'File name',
      selector: (row) => row.metadata.sourceName,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      grow: 1,
      maxWidth: '300px',
    },
  ];

  const requiresUserIdentification = () => {
    return requiredProperties.includes('user.identification');
  };

  const form = useForm<AdditionalIdentityForm>({
    initialValues: {
      frontFile: null,
      backFile: null,
      type: user.identification?.type || '',
      number: user.identification?.number || '',
    },
    validate: {
      type: RequiredFieldValidator(),
      number: RequiredFieldValidator(),
      frontFile: (val) => {
        return requiresUserIdentification() && !val
          ? 'Upload your ID as a PNG, JPEG or PDF file to continue'
          : null;
      },
    },
  });

  const formValues = form.values;
  const shouldHaveBackSide = ["Driver's License", 'Government ID'].includes(
    formValues.type,
  );

  const handleNextClick = async () => {
    const validationResult = form.validate();
    // if there's something to submit, submit it
    if (!validationResult.hasErrors && formValues.frontFile) {
      setLoading(true);
      try {
        await flexbaseOnboardingClient.updateUser({
          id: user.id,
          identification: {
            type: form.values.type,
            number: form.values.number,
          },
        });

        const filesToUpload = [
          { file: formValues.frontFile, description: formValues.type },
        ];

        if (formValues.backFile) {
          filesToUpload.push({
            file: formValues.backFile,
            description: `${formValues.type} - Back`,
          });
        }

        filesToUpload.forEach(async (file) => {
          await flexbaseOnboardingClient.uploadDocument({
            file: file.file,
            description: file.description,
            status: 'Pending Review',
          });
        });
        navigateToNextProductStep();
      } catch (e) {
        setError('Unable to update the document');
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    // if there's nothing to submit, and user has satisfied the requirement, allow move forward
    if (!form.values.frontFile && !requiresUserIdentification()) {
      navigateToNextProductStep();
    }
  };

  const fileReducer = (state: FileProps[], action: FileAction): FileProps[] => {
    switch (action.type) {
      case 'ADD_FILE':
        return [...state, action.file];
      case 'REMOVE_FILE':
        return state.filter((file) => file.name !== action.file.name);
      case 'RESET_FILES':
        return [];
      default:
        return state;
    }
  };

  const initialFilesState: FileProps[] = [];
  const [files, dispatch] = useReducer(fileReducer, initialFilesState);

  const handleDocumentType = (value: string) => {
    form.setValues({
      frontFile: null,
      backFile: null,
      type: value,
    });
    dispatch({
      type: 'RESET_FILES',
    });
  };

  const addFiles = async (newFiles: File[], isBackFile = false) => {
    const currentFile = newFiles[0];
    const imageUrl = URL.createObjectURL(currentFile);
    let thumbnailUrl: string | null = imageUrl;

    if (PDF_MIME_TYPE.includes(currentFile.type as 'application/pdf')) {
      thumbnailUrl = await getPdfThumbnailUrlFromUrl(imageUrl);
    }

    const file: FileProps = {
      name: currentFile.name,
      url: thumbnailUrl as string,
      type: isBackFile ? 'back' : 'front',
    };

    dispatch({
      type: 'ADD_FILE',
      file,
    });

    if (isBackFile) {
      form.setFieldValue('backFile', currentFile);
    } else {
      form.setFieldValue('frontFile', currentFile);
    }
  };

  const removeFiles = (file: FileProps, isBackFile?: boolean) => {
    dispatch({
      type: 'REMOVE_FILE',
      file: {
        ...file,
        type: isBackFile ? 'back' : 'front',
      },
    });

    if (isBackFile) {
      form.setFieldValue('backFile', null);
    } else {
      form.setFieldValue('frontFile', null);
    }
  };

  const frontFile = files.find((f) => f.type === 'front');
  const backFile = files.find((f) => f.type === 'back');

  return (
    <OnboardingStep
      title={'Additional identity verification'}
      subtitle="We'll need some additional information for verification purposes."
      stepId="additional-identity"
      showContinueSpinner={loading}
      error={error}
      onBackClick={goBack}
      onNextClick={handleNextClick}
      bottomDisclosureText={internationalWireDisclaimer}
    >
      <FlexbaseSelect
        data={ALLOWED_DOCS}
        value={formValues.type}
        label="Select ID type"
        onChange={handleDocumentType}
      />
      <TextInput
        mt="md"
        label="Identification Number"
        {...form.getInputProps('number')}
      />

      <Grid my="xl">
        <Grid.Col span={shouldHaveBackSide ? 6 : 'auto'}>
          {formValues.frontFile && frontFile ? (
            <FilePreviewSection
              file={frontFile}
              onRemoveFiles={() => removeFiles(frontFile)}
            />
          ) : (
            <>
              <DropzoneSection
                onDrop={(f) => addFiles(f)}
                maxSize={MAX_FILE_SIZE}
                onReject={() =>
                  form.setFieldError(
                    'frontFile',
                    `The file can't be larger than 25MB.`,
                  )
                }
                dropzoneText={shouldHaveBackSide ? 'Front side' : ''}
              />
              {form.errors.frontFile && (
                <Text mt="sm" color="critical.2" size="xs">
                  {form.errors.frontFile}
                </Text>
              )}
            </>
          )}
        </Grid.Col>
        {shouldHaveBackSide && (
          <Grid.Col span={6}>
            {formValues.backFile && backFile ? (
              <FilePreviewSection
                file={backFile}
                onRemoveFiles={() => removeFiles(backFile, true)}
              />
            ) : (
              <>
                <DropzoneSection
                  onDrop={(f) => addFiles(f, true)}
                  maxSize={MAX_FILE_SIZE}
                  onReject={() =>
                    form.setFieldError(
                      'backFile',
                      `The file can't be larger than 25MB.`,
                    )
                  }
                  dropzoneText="Back side"
                />
                {form.errors.backFile && (
                  <Text mt="sm" color="critical.2" size="xs">
                    {form.errors.backFile}
                  </Text>
                )}
              </>
            )}
          </Grid.Col>
        )}
      </Grid>
      {existingDocs && users ? (
        <FlexbaseTable
          data={existingDocs
            .filter((d) => {
              return ALLOWED_DOCS.includes(d.description);
            })
            .map((doc) => {
              const userLookup = users.find((u) => u.id === doc.userId);
              const userName =
                userLookup &&
                `${userLookup?.firstName} ${userLookup?.lastName}`;
              return { ...doc, userName };
            })}
          columns={existingDocsTableColumns}
          defaultSortAsc={false}
          defaultSortFieldId="hidden_date"
          pagination={existingDocs && existingDocs?.length > 10}
          isFetchingData={loading}
        />
      ) : (
        false
      )}
    </OnboardingStep>
  );
};
