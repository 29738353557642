import { atom, selector } from 'recoil';
import { VerifyIdentityFormValues } from './steps/verify-identity/verify-identity';
import { ApplicationState } from '../../states/application/product-onboarding';
import { formatOnboardingApiDate } from '../../utilities/formatters/format-datetime';

export const VerifyIdentityFormState = atom<VerifyIdentityFormValues>({
  key: 'verify_identity_v2',
  default: selector({
    key: 'verify_identity_v2/default',
    get: ({ get }) => {
      const { user } = get(ApplicationState);
      return {
        birthDate: formatOnboardingApiDate(user.birthDate),
        taxId: user.taxId || '',
      };
    },
  }),
});
