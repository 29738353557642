import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../states/application/product-onboarding';
import { useForm } from '@mantine/form';
import { validateRequired } from '../../../../../utilities/validators/validate-required';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import FlexbaseInput from 'components/input/flexbase-input';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryBusinessPurposeForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ company }, setStatus] = useRecoilState(ApplicationState);
  const personalInfoForm = useForm({
    initialValues: {
      businessPurpose: company.businessPurpose,
    },
    validate: {
      businessPurpose: (val) =>
        validateRequired(val) ? null : 'Purpose is required',
    },
  });

  const onSave = async () => {
    const validationResult = personalInfoForm.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { businessPurpose } = personalInfoForm.values;
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        businessPurpose,
      });
      setStatus((prev) => ({
        ...prev,
        company: { ...company, businessPurpose },
      }));
    } catch (e) {
      throw 'Could not update name';
    }
  };

  return (
    <SummaryEditBox
      editInputs={
        <div className={classes.inputContainer}>
          <FlexbaseInput
            placeholder="Business purpose"
            label="What is the your business's purpose?"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...personalInfoForm.getInputProps('businessPurpose')}
            id="summary-first-name"
          />
        </div>
      }
      savePromise={onSave}
      label={'Business purpose'}
      value={company.businessPurpose}
      onCancelClick={() => personalInfoForm.reset()}
      classNames={classNames}
    />
  );
};

export default SummaryBusinessPurposeForm;
