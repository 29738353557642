import type {
  PlaidAccount,
  DepositAccount,
} from '../../areas/banking/move-funds/move-funds.model';

export type PaymentForm = {
  id?: string;
  amount: number;
  status?: string;
  reason?: string;
  fromAccount?: DepositAccount | PlaidAccount;
  toAccount?: DepositAccount | PlaidAccount;
};

export const paymentFormInitial = {
  id: '',
  amount: 0,
  fromAccount: undefined,
  toAccount: undefined,
} as PaymentForm;
