import {
  BeneficiaryResponse,
  isBeneficiaryResponse,
} from '../../../components/send-payment/international-payments/util/types';
import { RecipientPaymentMethod } from '../../../../../states/recipient/recipient';
import { omit } from 'underscore';
import { ParsedAccount } from '../types';
import { sortDate } from '@utilities/dates/dates';
import { MoneyMovementWithSender } from '../payments-summary/payments-summary';
import { DateTime } from 'luxon';

export const parseAccountsInfo = (
  accountsInfo: (BeneficiaryResponse | RecipientPaymentMethod)[],
): ParsedAccount[] => {
  const getUniqueObjects = (arr: Partial<ParsedAccount>[]) => {
    const uniqueSet = new Set();
    const uniqueArray: any[] = [];

    arr.forEach((item) => {
      const itemWithoutId = omit(item, 'id', 'frecencyScore');
      const serializedItem = JSON.stringify(itemWithoutId);
      if (!uniqueSet.has(serializedItem)) {
        uniqueSet.add(serializedItem);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  };

  // done below to preserve property order
  return getUniqueObjects(
    accountsInfo.map((accountInfo) => {
      let res: Partial<ParsedAccount> = {};
      if (!isBeneficiaryResponse(accountInfo)) {
        res = {
          id: accountInfo.id,
          type: accountInfo.type,
          name: accountInfo.name,
          status: accountInfo.status,
          recipientId: accountInfo.recipientId,
          frecencyScore: accountInfo.frecencyScore,
          accountNumber: accountInfo.accountNumber,
          routingNumber: accountInfo.routingNumber,
        };

        if (accountInfo.nickName) {
          res.nickName = accountInfo.nickName;
        }
        if (accountInfo.accountType) {
          res.accountType = accountInfo.accountType;
        }
        if (accountInfo.bank) {
          res.bank = accountInfo.bank;
        }
      } else {
        const ccResponse = accountInfo.ccResponse;

        res = {
          id: accountInfo.id,
          name: accountInfo.name,
          bank: ccResponse.bankName,
          status: accountInfo.status,
          country: accountInfo.country,
          currency: accountInfo.currency,
          bicSwiftCode: ccResponse.bicSwift,
          bankCountry: ccResponse.bankCountry,
          recipientId: accountInfo.recipientId,
          recipientType: accountInfo.entityType,
          accountNumber: ccResponse.accountNumber,
          frecencyScore: accountInfo.frecencyScore,
          type:
            accountInfo.currency === 'USD'
              ? 'internationalWireUSD'
              : 'internationalWire',
        };

        if (accountInfo.nickName) {
          res.nickName = accountInfo.nickName;
        }
        if (ccResponse.iban) {
          res.ibanCode = ccResponse.iban;
        }
        if (ccResponse.routingCodeType1 && ccResponse.routingCodeValue1) {
          res.routingCode1 = `${ccResponse.routingCodeType1}: ${ccResponse.routingCodeValue1}`;
        }
        if (ccResponse.routingCodeType2 && ccResponse.routingCodeValue2) {
          res.routingCode2 = `${ccResponse.routingCodeType2}: ${ccResponse.routingCodeValue2}`;
        }
      }
      return res;
    }),
  );
};

export const filterAndSortPaymentsByDate = (
  date: string,
  paymentsData?: MoneyMovementWithSender[],
) => {
  const filterByDate = (payments: MoneyMovementWithSender[], d: string) => {
    if (!d) {
      return payments;
    }
    return payments.filter((p) => p.createdAt.includes(d));
  };

  const allPayments = paymentsData ?? [];
  const filteredPayments = date ? filterByDate(allPayments, date) : allPayments;

  return filteredPayments
    .map((payment) => ({
      ...payment,
      amount: `${Number(payment.payAmount) / 100}`,
    }))
    .sort((a, b) => sortDate(a, b, false));
};

/**
 * Returns an array of dates within a given interval.
 * If no interval is provided, it uses the first and last payment dates from the payments array.
 *
 * @returns Array of dates in the format 'yyyy-MM-dd'
 */
export const getIntervalDates = (
  payments: MoneyMovementWithSender[],
  fromDate?: string,
  toDate?: string,
) => {
  const allDates: string[] = [];

  if (!payments.length) {
    return allDates; // Return an empty array if there are no payments
  }

  let startDate = fromDate
    ? DateTime.fromISO(fromDate)
    : DateTime.fromSQL(payments[0].createdAt);
  let endDate = toDate
    ? DateTime.fromISO(toDate)
    : DateTime.fromSQL(payments[payments.length - 1].createdAt);

  // Ensure startDate is not after endDate
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  while (startDate <= endDate) {
    allDates.push(startDate.toFormat('yyyy-MM-dd'));
    startDate = startDate.plus({ days: 1 });
  }

  return allDates;
};
