import { DateTime } from 'luxon';
import { Box, Button, Center, Loader, Text } from '@mantine/core';
import { useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './auto-pay.styles';
import { showNotification } from '@mantine/notifications';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';
import { PlusSignIcon } from '../../../../assets/svg';
import {
  onError,
  usePlaidBankingComponent,
} from '@common/banking/plaid-banking-component';
import {
  ApplicationState,
  getProductOnboardingStatus,
  IsAdmin,
} from '../../../../states/application/product-onboarding';
import { useGetCompanyBalance } from '@queries/use-credit-payment';
import { LuPencilLine } from 'react-icons/lu';
import useModal from '@common/modal/modal-hook';
import AutoPaySettings from './auto-pay-settings';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import AccountSelection from 'areas/payments/components/account-selection';
import {
  AccountProps,
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';

const AutoPay = () => {
  const { classes, theme } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const [loading, setLoading] = useState(false);
  const [
    {
      company: { autopay, ...company },
    },
    setCompany,
  ] = useRecoilState(ApplicationState);
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const [accountLoading, setAccountLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<AccountProps | null>(
    null,
  );
  const { data: companyBalance, isLoading } = useGetCompanyBalance(company.id);
  const { data: depositAccountsData, isLoading: isDepositAccountsLoading } =
    useGetDepositAccounts();

  // extracted `depositAccountId` and `plaidTokenId` to only retain a
  // single value for the main account for the autopay.
  const { depositAccountId, plaidTokenId, ...autopayConfig } = autopay;

  const plaidAccounts = company.financialInstitutions
    .filter((fi) => fi.active && !fi.unlinked)
    .map((fi) => {
      return {
        ...fi,
        plaidOrDeposit: 'plaid',
      } as PlaidAccount;
    });

  const depositAccounts =
    depositAccountsData?.accounts
      .filter((acc) => acc.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((acc) => {
        return {
          ...acc,
          plaidOrDeposit: 'deposit',
        } as DepositAccount;
      }) ?? [];

  const accountsList = [...plaidAccounts, ...depositAccounts];

  const getSelectedAccount = (id: string) => {
    return accountsList.find((acc) => acc.id === id) ?? null;
  };

  // The priority order for checking the default account for the autopay is as follows:
  // depositAccountId -> plaidTokenId -> primaryPlaidAccount
  const primaryAccount = plaidAccounts.find(
    ({ id }) => id === company.primaryPlaidAccount,
  );

  const initAccount =
    depositAccountId || plaidTokenId
      ? (getSelectedAccount(depositAccountId ?? '') ??
        getSelectedAccount(plaidTokenId ?? ''))
      : getSelectedAccount(primaryAccount?.id ?? '');

  const currentAccount = selectedAccount
    ? selectedAccount
    : (initAccount ?? accountsList[0]);

  const { open } = usePlaidBankingComponent({
    onSuccess: () => onLinkBank(),
    onError: () => {
      onError();
      setAccountLoading(false);
    },
    setLoading,
  });
  const now = DateTime.now().toString();
  const autoPayPct = autopay?.payBalancePct;

  const onLinkBank = async () => {
    showNotification({
      color: 'flexbase-teal',
      title: 'Bank Account Linked!',
      message: 'Now you may select it as your default payment account.',
      autoClose: 10000,
    });
    try {
      setAccountLoading(true);
      const {
        company: { financialInstitutions },
      } = await getProductOnboardingStatus();
      setCompany((prev) => ({
        ...prev,
        company: { ...prev.company, financialInstitutions },
      }));
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to refresh linked bank account info',
      });
    } finally {
      setAccountLoading(false);
    }
  };

  const handleLinkPlaidAccount = () => {
    setAccountLoading(true);
    open();
  };

  const onAccountChange = async (value: string) => {
    setAccountLoading(true);
    try {
      const account = getSelectedAccount(value);

      const autopayForm =
        account?.plaidOrDeposit === 'deposit'
          ? { autopay: { ...autopayConfig, depositAccountId: value } }
          : {
              primaryPlaidAccount: value,
              autopay: { ...autopayConfig, plaidTokenId: value },
            };

      const result = await flexbaseOnboardingClient.updateCompany(autopayForm);
      setSelectedAccount(account);
      setCompany((prev) => ({
        ...prev,
        company: result,
      }));

      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: 'Successfully updated payment account',
      });
    } catch (error) {
      console.error('Unable to update repayment account.', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to update repayment account.',
      });
    } finally {
      setAccountLoading(false);
    }
  };

  const handleEditPencilClicked = () => {
    modal.openFullModal(
      <AutoPaySettings
        creditLimit={companyBalance?.creditLimit ?? 0}
        closeModal={closeModal}
      />,
    );
  };

  return (
    <div className={classes.baseContainer}>
      <div className={classes.autoPayContainer}>
        <TransparentCenteredLoader visible={loading} />
        <div className={classes.autoPaySettings}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 28,
              marginBottom: 5,
            }}
          >
            <Text className={classes.title}> Auto pay settings </Text>
            {/*We can now show this all the time for admins since you can't turn off auto pay now that the toggle is gone.
             We're only slightly anti-consumer now :)
            */}
            {isAdmin && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text className={classes.grayTitle}>
                  {autopay?.when
                    ? autoPayPct && autoPayPct !== 100
                      ? `Auto pay ${autoPayPct}%`
                      : `Auto pay full balance`
                    : 'Edit auto pay'}
                </Text>
                <LuPencilLine
                  style={{
                    marginLeft: theme.spacing.xs,
                    color: theme.colors.primarySecondarySuccess[2],
                    cursor: 'pointer',
                  }}
                  onClick={handleEditPencilClicked}
                />
              </div>
            )}{' '}
          </div>
          {isLoading || isDepositAccountsLoading ? (
            <Center h="80%">
              <Loader size="md" />
            </Center>
          ) : (
            <>
              <Text className={classes.dateAmountDue}>
                {companyBalance?.billDate
                  ? DateTime.fromISO(companyBalance.billDate).toFormat('LLL dd')
                  : DateTime.fromISO(now).toFormat('LLL dd')}{' '}
                for{' '}
                {formatCurrency(
                  companyBalance?.minimumDue && companyBalance.minimumDue > 0
                    ? companyBalance?.minimumDue
                    : 0,
                )}
              </Text>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text className={classes.title}>
                    {autopay?.when
                      ? 'Default payment account'
                      : 'Enable auto pay to set default payment account'}
                  </Text>
                </div>
                <Box mt={8}>
                  {currentAccount && (
                    <AccountSelection
                      accounts={accountsList}
                      currentAccount={currentAccount}
                      onAccountChange={(account) => onAccountChange(account.id)}
                      showAccountFilters
                      disabled={
                        accountLoading ||
                        isDepositAccountsLoading ||
                        !autopay?.when
                      }
                    />
                  )}
                  <Button
                    mt="md"
                    variant="outline"
                    w="fit-content"
                    disabled={accountLoading}
                    leftIcon={<PlusSignIcon />}
                    onClick={handleLinkPlaidAccount}
                  >
                    Link New Account
                  </Button>
                </Box>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoPay;
