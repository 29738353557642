import {
  useCreateBillpayInvoice,
  useCreateBillpayInvoiceLineItem,
  useGetBillpayInvoice,
  useGetBillpayInvoiceLineItems,
  useGetBillpayInvoices,
  useUpdateBillpayInvoiceLineItem,
} from '../../queries/use-bill-pay';
import { useGetBankingPayments } from '../../queries/use-payments';
import {
  useGetAllRecipients,
  useGetRecipient,
} from '../../queries/use-recipients';
import { LineItemInput } from './create-invoice/invoice-wizard';
import { useGetPlatformDocument } from '../../queries/use-get-platform-document';
import { useMemo, useState } from 'react';
import {
  CreateBillpayInvoiceLineItemRequest,
  UpdateBillpayInvoiceLineItemRequest,
} from '../../states/bill-pay/bill-pay';
import { DateTime } from 'luxon';

export const useBillpayInvoicesWithRecipients = () => {
  const now = useMemo(() => DateTime.now().toISO(), []);
  const {
    data: recipientsData,
    isLoading: isRecipientsLoading,
    error: recipientsError,
  } = useGetAllRecipients();
  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: invoicesError,
  } = useGetBillpayInvoices({ sort: 'desc', validAt: now });

  const isLoading = isRecipientsLoading || isInvoicesLoading;
  const error = recipientsError || invoicesError;

  if (isLoading) {
    return {
      data: { invoices: [] },
      isLoading: true,
      isSuccess: false,
      error: null,
    };
  }

  if (error) {
    return {
      data: { invoices: [] },
      isLoading: false,
      isSuccess: false,
      error,
    };
  }

  const recipientsMap = new Map(
    recipientsData?.recipients.map((recipient) => [
      recipient.id,
      recipient.name || 'Unknown Recipient',
    ]),
  );

  const combinedData = invoicesData?.invoices.map((invoice) => ({
    ...invoice,
    recipientName:
      recipientsMap.get(invoice.recipientId) || 'Unknown Recipient',
  }));

  return {
    data: { invoices: combinedData },
    isLoading: false,
    isSuccess: true,
    error: null,
  };
};

const lineItemUpdateError = new Error(
  'Error creating line items, please try again.',
);

export const useUpdateBillpayInvoiceLineItems = () => {
  const {
    mutateAsync: updateBillpayInvoiceLineItem,
    isPending: isUpdateBillpayInvoiceLineItemPending,
  } = useUpdateBillpayInvoiceLineItem();
  const {
    mutateAsync: createBillpayInvoiceLineItem,
    isPending: isCreateBillpayInvoiceLineItemPending,
  } = useCreateBillpayInvoiceLineItem();

  const [
    updateBillpayInvoiceLineItemError,
    setUpdateBillpayInvoiceLineItemError,
  ] = useState<Error | null>(null);

  const isPending =
    isUpdateBillpayInvoiceLineItemPending ||
    isCreateBillpayInvoiceLineItemPending;
  const mutationsError = updateBillpayInvoiceLineItemError;

  const mutateAsync = async ({
    lineItems,
    invoiceId,
  }: {
    lineItems: LineItemInput[];
    invoiceId: string;
  }) => {
    try {
      for (const item of lineItems) {
        const lineItem = {
          ...item,
          billpayInvoicesId: invoiceId,
        };
        if (lineItem.description && lineItem.quantity && lineItem.total) {
          // If line item has an id, update it. Otherwise, create it.
          if (lineItem.id) {
            await updateBillpayInvoiceLineItem({
              lineItem: lineItem as UpdateBillpayInvoiceLineItemRequest,
            });
          } else {
            await createBillpayInvoiceLineItem({
              lineItem: lineItem as CreateBillpayInvoiceLineItemRequest,
            });
          }
        }
      }
    } catch (err) {
      console.error('error in line item update: ', err);
      setUpdateBillpayInvoiceLineItemError(lineItemUpdateError);
    }
  };

  return {
    mutateAsync,
    isPending,
    error: mutationsError,
  };
};

const lineItemCreationError = new Error(
  'There was an error saving the invoice line items, please try again.',
);
const invoiceCreationError = new Error(
  'There was an error saving the invoice, please try again.',
);

export const useCreateBillpayInvoiceAndLineItems = () => {
  const {
    mutate: createBillpayInvoice,
    isPending: isCreateBillpayInvoicePending,
  } = useCreateBillpayInvoice();
  const {
    mutateAsync: createBillpayInvoiceLineItem,
    isPending: isCreateBillpayInvoiceLineItemPending,
  } = useCreateBillpayInvoiceLineItem();

  const [
    createBillpayInvoiceLineItemError,
    setCreateBillpayInvoiceLineItemError,
  ] = useState<Error | null>(null);

  const [createBillpayInvoiceError, setCreateBillpayInvoiceError] =
    useState<Error | null>(null);

  const isPending =
    isCreateBillpayInvoicePending || isCreateBillpayInvoiceLineItemPending;
  const mutationsError =
    createBillpayInvoiceError || createBillpayInvoiceLineItemError;

  const mutate = async (
    {
      lineItems,
      recipientId,
      dueDate,
      invoiceTotal,
      existingDocumentId,
    }: {
      recipientId: string;
      dueDate: string;
      lineItems: LineItemInput[];
      invoiceTotal: number;
      existingDocumentId?: string;
    },
    {
      onSuccess,
      onError,
    }: {
      onSuccess?: (invoiceId: string) => void;
      onError?: (error: Error) => void;
    },
  ) => {
    if (existingDocumentId && dueDate) {
      const invoicePayload = {
        invoice: {
          documentId: existingDocumentId,
          recipientId,
          total: invoiceTotal,
          dueDate: dueDate,
        },
      };
      // create invoice
      createBillpayInvoice(invoicePayload, {
        onSuccess: async (invoiceResponse) => {
          // create line items
          try {
            await Promise.all(
              lineItems?.map((item) => {
                if (item.description && item.quantity && item.total) {
                  return createBillpayInvoiceLineItem({
                    lineItem: {
                      description: item.description,
                      quantity: item.quantity,
                      total: item.total,
                      billpayInvoicesId: invoiceResponse?.invoice?.id,
                      status: 'active',
                    },
                  });
                }
              }),
            );
            onSuccess?.(invoiceResponse?.invoice.id);
          } catch (error) {
            console.error('error in line item creation: ', error);
            setCreateBillpayInvoiceLineItemError(lineItemCreationError);
            onError?.(lineItemCreationError);
          }
        },
        onError: (err) => {
          console.error('error in invoice creation: ', err);
          setCreateBillpayInvoiceError(invoiceCreationError);
          onError?.(invoiceCreationError);
        },
      });
    }
  };

  return {
    mutate,
    isPending,
    error: mutationsError,
  };
};

export const useBillpayInvoiceById = (invoiceId: string) => {
  const {
    data: invoiceData,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = useGetBillpayInvoice(invoiceId);

  const {
    data: lineItemsData,
    isLoading: isLineItemsLoading,
    error: lineItemsError,
  } = useGetBillpayInvoiceLineItems(invoiceId);

  const {
    data: recipientData,
    isLoading: isRecipientLoading,
    error: recipientError,
  } = useGetRecipient(invoiceData?.invoice?.recipientId || '');
  const {
    data: documentData,
    isLoading: isDocumentLoading,
    error: documentError,
  } = useGetPlatformDocument(invoiceData?.invoice?.documentId || '');
  const isLoading =
    isInvoiceLoading ||
    isLineItemsLoading ||
    isRecipientLoading ||
    isDocumentLoading;
  const error =
    invoiceError || lineItemsError || recipientError || documentError;

  if (isLoading) {
    return {
      data: null,
      isLoading: true,
      isSuccess: false,
      error: null,
    };
  }

  if (error) {
    return {
      data: null,
      isLoading: false,
      isSuccess: false,
      error,
    };
  }

  return {
    data: {
      ...invoiceData?.invoice,
      lineItems: lineItemsData?.invoiceLineItems,
      recipient: recipientData?.recipient,
      document: documentData,
    },
    isLoading: false,
    isSuccess: true,
    error: null,
  };
};

export const useBillPayPayments = () => {
  const now = useMemo(() => DateTime.now().toISO(), []);
  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    isSuccess: isPaymentsSuccess,
    error: paymentsError,
    refetch,
  } = useGetBankingPayments({ paymentScope: 'billpay' });

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    isSuccess: isInvoicesSuccess,
    error: invoicesError,
    refetch: invoicesRefetch,
  } = useGetBillpayInvoices({ sort: 'desc', validAt: now });

  const isLoading = isPaymentsLoading || isInvoicesLoading;
  const isSuccess = isPaymentsSuccess && isInvoicesSuccess;
  const error = paymentsError || invoicesError;

  const handleRetry = () => {
    refetch();
    invoicesRefetch();
  };

  const invoicesMap = new Map(
    invoicesData?.invoices.map((invoice) => [
      invoice.id,
      invoice.invoiceNumber || 'N/A',
    ]),
  );

  const combinedData =
    paymentsData?.map((payment) => ({
      ...payment,
      invoiceNumber: invoicesMap.get(payment.billpayInvoicesId ?? '') || 'N/A',
    })) ?? [];

  return {
    data: { payments: combinedData },
    isLoading,
    isSuccess,
    error,
    refetch: handleRetry,
  };
};
