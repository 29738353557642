import {
  PlaidAccount,
  Tier,
} from '../../areas/banking/move-funds/move-funds.model';
import {
  AvailableCurrencies,
  BeneficiaryResponse,
  IndividualOrCompany,
} from '../../areas/payments/components/send-payment/international-payments/util/types';
import { DateTime } from 'luxon';
import { CardExpensesTypes } from './card.model';

type ErrorResponse = {
  error?: string;
};

export type InstitutionResponse = {
  success: boolean;
  institution: Institution;
};

export type Institution = {
  routingNumber: string;
  name: string;
  address: string;
  isACHSupported: boolean;
  isWireSupported: boolean;
};

export type PlaidRelinkRequest =
  | { accessToken: string }
  | { plaidTokenId: string }
  | { bankName: string };

export type PlaidRelinkResponse = {
  success: boolean;
  response: {
    expiration: string;
    link_token: string;
    request_id: string;
  };
};

export type PlaidBalances = {
  success: boolean;
  accounts: PlaidBalanceAccount[];
};

export type PlaidBalanceAccount = {
  accountId: string;
  balances: Balances;
  mask: string;
  name: string;
  officialName: string;
  subtype: string;
  type: string;
  itemId: string;
  institutionId: string;
};

export type Balances = {
  available: number;
  current: number;
  isoCurrencyCode: string;
  limit: any;
  unofficialCurrencyCode: any;
};

export type PlaidAccounts = {
  success: boolean;
  accounts?: PlaidAccount[];
};

export type Account = {
  accessToken: string;
  account: string;
  accountId: string;
  accountName: string;
  accountType: string;
  bankName: string;
  companyId: string;
  id: string;
  itemId: string;
  officialName: string;
  routing: string;
  stripeBankAccountToken: string;
  unitProcessorToken: string;
  userId: string;
  unlinked: boolean;
  tenantId: string;
  logoUrl: string;
};

export type CounterpartyResponse = {
  success: boolean;
  counterparty: Counterparty;
};

export type CounterpartyResponseList = {
  success: boolean;
  counterparties: Counterparty[];
};

export type CounterpartyInfoResponse = {
  success: boolean;
  counterparty: Counterparty;
};

export type Address = {
  address: string;
  addressLine2?: string | null;
  // how we get the address object from UnitCo
  street?: string;
  street2?: string | null;
  city: string;
  country: string;
  postalCode: string;
  state: string;
};
export type Counterparty = {
  accountNumber: string;
  accountType?: 'Checking' | 'Savings';
  routingNumber: string;
  asOf: string;
  companyId: string;
  createdAt: string;
  id: string;
  response: UnitCounterpartyResponse;
  status: 'active' | 'inactive';
  type: 'ach' | 'wire' | 'internationalWire' | 'book';
  nickName: string;
  name: string;
  userId: string;
  version: number;
  tenantId: string;
  lastUsedAt: string;
  address?: Address;
  bank?: string;
  frecencyScore: number;
  country?: string;
  recipientId: string;
};

export type UnitCounterpartyResponse = {
  data: CounterpartyData;
};

export type CounterpartyData = {
  attributes: CounterpartyAttributes;
  id: string;
  relationships: Relationships;
  type: string;
};

export type CounterpartyAttributes = {
  accountNumber: string;
  accountType: string;
  bank?: string;
  createdAt: string;
  name: string;
  permissions: string;
  routingNumber: string;
  tags?: Tags;
  type: string;
};

export type Tags = {
  companyId?: string;
  userId?: string;
  tenantId?: string;
};

export type Relationships = {
  customer: Customer;
};

export type Customer = {
  data: UnitCoMetaData;
};

export type CounterpartyAch = {
  name: string;
  nickName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
};

export type CounterpartyWire = {
  name: string;
  nickName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  address: {
    city: string;
    state: string;
    country: string;
    postalCode: string;
    address: string;
    addressLine2?: string;
  };
};

export type PaymentType = 'ach' | 'wire' | 'flex';
export type AccountType = 'depositAccount' | 'plaidAccount' | 'creditAccount';

export type CounterpartyRequest = {
  type: PaymentType;
  counterparty: CounterpartyAch | CounterpartyWire;
};

export type MoneyMovementStatus =
  | 'AwaitingApproval'
  | 'AwaitingConfirmation'
  | 'Scheduled'
  | 'Queued'
  | 'Pending'
  | 'PendingReview'
  | 'Clearing'
  | 'Rejected'
  | 'Sent'
  | 'Returned'
  | 'Canceled';

type MoneyMovementBase = {
  id: string;
  type: 'ach' | 'wire' | 'book' | 'internationalWire';
  asOf: string;
  byUser?: string;
  createdAt: string;
  settlementDate?: string;
  companyId: string;
  counterpartyId?: string;
  depositId: string;
  docs?: string[];
  authorizations?: string[];
  depositIdTo?: string;
  scheduledFor?: string;
  payAmount: string;
  payAmountCents: string;
  payCtrParty: string;
  payDescription: string;
  expectedCompletionDate?: string;
  payDirection: 'Credit' | 'Debit';
  status: MoneyMovementStatus;
  reason?: string;
  userId: string;
  version: number;
  tenantId: string;
  notes: string;
  approvedBy?: string;
  internationalPaymentId?: string;
  confirmedBy?: string;
  billpayInvoicesId?: string;
  origin: string;
};

export type MoneyMovement = (
  | {
      type: 'ach';
      payDirection: 'Credit';
      plaidTokenId?: string;
    }
  | {
      type: 'ach';
      payDirection: 'Debit';
      plaidTokenId?: string;
    }
  | {
      type: 'wire';
      payDirection: 'Credit';
    }
  | {
      type: 'book';
      payDirection: 'Credit';
      depositIdTo?: string;
    }
  | {
      type: 'internationalWire';
      payDirection: 'Credit';
      internationalPaymentId: string;
    }
) &
  MoneyMovementBase;

export type MoneyMovementResponse = {
  success: boolean;
  payment: MoneyMovement;
};

export type GetMoneyMovementsParams = {
  paymentId?: string;
  depositId?: string;
  counterpartyId?: string;
  recipientId?: string;
  fromDate?: DateTime<boolean>;
  toDate?: DateTime<boolean>;
  limit?: number;
  cursor?: string;
  paymentScope?: string;
  chargeCardAccountId?: string;
};

export type MoneyMovementListReponse = {
  success: boolean;
  payments: MoneyMovement[];
};

export type PaymentInfoReponse = {
  success: boolean;
  payment: MoneyMovement;
};

// deposit response
export type DepositListResponse = {
  success: boolean;
  accounts: DepositAccount[];
};

export type DepositAccInfoResponse = {
  success: boolean;
  account: DepositAccount;
};

export type DepositAccountHistoryParams = {
  before?: string;
  after?: string;
  companyId?: string;
};

type DepositAccountHistoryBalance = {
  available: number;
  balance: number;
  date: string;
  depositAccountId: string;
  hold: number;
  overdraftLimit: number;
};

export type DepositAccountHistoryResponse = {
  average: number;
  balances: DepositAccountHistoryBalance[];
  statements: DepositAccountHistoryBalance[];
  success: boolean;
};

export type CreateDepositAccountRequest = {
  isDefault?: boolean;
  nickName?: string;
};
export type CreateDepositAccountResponse = {
  success: boolean;
  id: string;
  status: string;
  message: string;
  depositAccount: DepositAccount;
};

export type UpdateDepositAccountNicknameResponse = {
  success: boolean;
  depositAccount: DepositAccount;
};

export type DepositAccount = {
  plaidOrDeposit: 'deposit';
  asOf: string;
  companyId: string;
  createdAt: string;
  id: string;
  isDefault: boolean;
  name: string;
  nickName: string;
  status: string;
  type: AccountType;
  accountType: 'checking';
  userId: string;
  version: number;
  tier: Tier;
  routingNumber: string;
  accountNumber: string;
  balance: number;
  available: number;
};

export type UnitCoMetaData = {
  id: string;
  type: string;
};

type CreateMoneyMovementBase = {
  accountId: string;
  amount: string;
  description: string;
  notes?: string;
  authorizations?: [
    {
      contents: string;
    },
  ];
  attachments?: string[];
  scheduledFor?: string | null;
  idempotencyToken?: string;
};

export type CreateInternationalWireMoneyMovement = {
  type: 'internationalWire';
  direction: 'Credit';
  beneficiaryId: string;
  reason: string;
  amount: number;
  sourceOfFunds: string;
  purposeCode: string;
} & CreateMoneyMovementBase;

export type CreateWireMoneyMovement = {
  type: 'wire';
  direction: 'Credit';
  counterpartyId: string;
} & CreateMoneyMovementBase;

export type CreateBookeMoneyMovement = {
  type: 'book';
  direction: string;
  accountIdTo: string;
  description: string;
} & CreateMoneyMovementBase;

export type DebitAuthorization = {
  contents: string;
};

export type CreateACHMoneyMovement = (
  | {
      type: 'ach';
      direction: 'Credit';
      counterpartyId: string;
    }
  | {
      type: 'ach';
      direction: 'Credit';
      plaidTokenId: string;
    }
  | {
      type: 'ach';
      direction: 'Debit';
      plaidTokenId: string;
      authorizations: DebitAuthorization[];
    }
) &
  CreateMoneyMovementBase;

export type CreateMoneyMovementRequest =
  | CreateWireMoneyMovement
  | CreateACHMoneyMovement
  | CreateBookeMoneyMovement
  | CreateInternationalWireMoneyMovement;

export type CustTokenVGS = {
  verificationToken: string;
  verificationCode: string;
};

export const domesticPaymentTypes = new Set([
  'Internal transfer',
  'Domestic ACH payment',
  'Domestic wire',
]);

export type TransactionParams = {
  transactionId?: string;
  depositAccountId?: string;
  fromDate?: string;
  toDate?: string;
};

export type TransactionResponse = {
  success: boolean;
  transactions: Transaction[];
};

export type Transaction = {
  accountName: string;
  accountNumber: string;
  amount: string;
  balance: string;
  cardId: string | null; // deprecate soon
  companyName: string;
  createdAt: string;
  depositAccount: string; // deprecate soon
  depositAccountId: string;
  direction: 'Credit' | 'Debit' | '';
  id: string;
  summary: string;
  whoDisplayName?: string;
  whoFirstName?: string;
  whoLastName?: string;
  whoUsername?: string;
  type: string;
  moneyMovement?: boolean;
  status?: string; // only on money movement requests.
  paymentId?: string;
  paymentType?: string;
  checkDepositId?: string;
};

export type TreasuryAllocationsResponse = {
  allocations: TreasuryAllocation[];
  closingBalance: string;
} & FlexbaseResponse;

export type TreasuryAllocation = {
  admAcctId: string;
  amount: string;
  asOf: string;
  companyId: string;
  datePosted: string;
  id: string;
  instCity: string;
  instId: string;
  instLogoUrl: string;
  instName: string;
  instState: string;
  instUrl: string;
  tenantId: string;
};

export type TreasuryActivityResponse = {
  activity: TreasuryActivity[];
} & FlexbaseResponse;

export type TreasuryActivity = {
  asOf: string;
  byUser: string;
  createdAt: string;
  datePosted: string;
  id: string;
  status: string;
  failureReason: any;
  admAcctId: string;
  userId: string;
  companyId: string;
  version: number;
  activity: string;
  amount: number;
};

export type TreasuryAccountResponse = {
  account: TreasuryAccount;
} & FlexbaseResponse;

export type TreasuryAccount = {
  asOf: string;
  byUser: string;
  companyId: string;
  createdAt: string;
  id: string;
  admName: string;
  plaidTokenId: string;
  userId: string;
  version: number;
};

export type Limits = {
  dailyWithdrawal?: number;
  dailyPurchase?: number;
  monthlyWithdrawal?: number;
  monthlyPurchase?: number;
};

export type CardType =
  | 'individualDebitCard'
  | 'businessDebitCard'
  | 'individualVirtualDebitCard'
  | 'businessVirtualDebitCard'
  | ChargeCardType;

export type ChargeCardType =
  | 'businessCreditCard'
  | 'businessVirtualCreditCard'
  | 'individualVirtualCreditCard'
  | 'individualCreditCard';

export type IssueDebitCard = {
  limits?: Limits | null;
  cardName?: string | null;
  depositAccountId: string;
  shipping?: Address;
  type: 'physical' | 'virtual';
  cardSubtype: CardType;
  issuer: string;
  toUser: {
    id: string;
  };
  shippingAddress?: Address;
};

export type MonthToDateSpends = {
  mtdSpend: number;
  startOfMonth: string;
};

export type CardByUser = {
  address: Address;
  asOf: string;
  cardName: string;
  cardNumber: string;
  cardSubtype: CardType;
  cardType: string;
  creditLimit: string;
  expirationDate: string;
  expensesTypes: Limits;
  holder: string;
  id: string;
  monthToDateSpends: MonthToDateSpends;
  shipTo: Address;
  status: string;
  ucCardId: string;
  userId: string;
  depositAccountId: string;
  chargeCardAccountId: string;
};

export type DebitCardResponse = {
  success: boolean;
  card: CardByUser;
  error?: string;
};

export type DebitCardsResponse = {
  success: boolean;
  cards?: CardByUser[];
  error?: string;
};

export type CardStatus =
  | 'lost'
  | 'stolen'
  | 'freeze'
  | 'unfreeze'
  | 'cancelled';

export type UpdateCardRequest = {
  id: string;
  cardName?: string;
  limits?: Limits;
  shippingAddress?: Address;
  expensesTypes?: CardExpensesTypes & Limits;
};

export type DebitCardPinStatusResponse = {
  success: boolean;
  status: {
    data: {
      type: string;
      attributes: {
        status: string;
      };
    };
  };
};

export type ApplicationType = 'individualApplication' | 'businessApplication';

export type ApplicationDocument = {
  applicationDocId: string;
  documentType: string;
  status: string;
  description?: string;
  name?: string;
};

// get application response
export type BankingApplicationStatus =
  | 'PendingReview'
  | 'Pending'
  | 'Approved'
  | 'Denied'
  | 'Canceled'
  | 'AwaitingDocuments'
  | 'Unqualified'
  | 'Incomplete'
  | 'Unused'
  | 'Ready';

type GetApplicationStatusErrorResponse = { success: false; error: string };
export type GetApplicationStatusAwaitingDocumentsResponse = {
  success: true;
  type: ApplicationType;
  status: 'AwaitingDocuments';
  url?: string;
  documents: ApplicationDocument[];
};
export type GetApplicationStatusApplicationResponse = {
  success: true;
  type: ApplicationType;
  status: BankingApplicationStatus;
};
export type GetApplicationStatusResponse =
  | GetApplicationStatusErrorResponse
  | GetApplicationStatusAwaitingDocumentsResponse
  | GetApplicationStatusApplicationResponse;
export function BankApplicationStatusIsAwaitingDocuments(
  response: GetApplicationStatusResponse,
): response is GetApplicationStatusAwaitingDocumentsResponse {
  return response.success && response.status === 'AwaitingDocuments';
}
export function BankApplicationStatusIsApplicationResponse(
  response: GetApplicationStatusResponse,
): response is GetApplicationStatusApplicationResponse {
  return response.success && response.status !== 'AwaitingDocuments';
}

// post application response
export type CreateApplicationStatusResponse = {
  success: boolean;
  id: string;
  status: BankingApplicationStatus;
  message: string;
  application: Application;
};

export type Application = {
  data: Data;
  included: any[];
};

export type Data = {
  attributes: Attributes;
  id: string;
  relationships: Relationships;
  type: string;
};

export type Attributes = {
  address: Address;
  archived: boolean;
  beneficialOwners: any[];
  contact: Contact;
  createdAt: string;
  entityType: string;
  message: string;
  name: string;
  officer: Officer;
  phone: Phone3;
  stateOfIncorporation: string;
  status: string;
  tags: Tags;
  updatedAt: string;
};

export type Contact = {
  fullName: FullName;
  phone: Phone;
};

export type FullName = {
  first: string;
  last: string;
};

export type Phone = {
  countryCode: string;
  number: string;
};

export type Officer = {
  address: Address2;
  fullName: FullName2;
  phone: Phone2;
  status: string;
  title: string;
};

export type Address2 = {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  street: string;
  street2: string;
};

export type FullName2 = {
  first: string;
  last: string;
};

export type Phone2 = {
  countryCode: string;
  number: string;
};

export type Phone3 = {
  countryCode: string;
  number: string;
};

type FlexbaseResponse = {
  success: boolean;
  error?: unknown;
};

export type GetUnitcoTokenResponse = {
  type?: string;
  attributes?: {
    verificationToken: string;
  };
} & FlexbaseResponse;

export type CreateUnitcoTokenResponse = {
  asOf?: string;
  expiresIn?: number;
  accessToken?: string;
} & FlexbaseResponse;

export type Statement = {
  id: string;
  type: 'accountStatementDTO';
  depositAccountId: string;
  attributes: {
    period: string;
  };
};

export type StatementsResponse = {
  statements: Statement[];
} & FlexbaseResponse;

export type BankingParameters = {
  isPdf?: boolean;
  pageLimit?: number;
  pageOffset?: number;
  fromDate?: DateTime;
  toDate?: DateTime;
  period?: DateTime;
  sort?: string;
  limit?: number;
  offset?: number;
  accountId?: string;
  cardId?: string;
  full?: boolean;
  depositAccountId?: string;
  paymentId?: string;
  customerId?: string;
};

export type WireInstructionsModel = {
  beneficiaryName: string;
  beneficiaryAccountNumber: string;
  beneficiaryRoutingNumber: string;
  beneficiaryAddress: string;
  beneficiaryBankName: string;
  beneficiaryBankRoutingNumber: string;
  beneficiaryBankAddress: string;
};

export type DomesticWireInstructionsModel = WireInstructionsModel & {
  receiverABA: string;
};

export type InternationalWireInstructionsModel = WireInstructionsModel & {
  receiverIBAN: string;
};

export type DomesticWireInstructionsResponse = DomesticWireInstructionsModel &
  FlexbaseResponse;

export type DocMetadata = {
  createdAt: string;
  docType: string;
  docId?: string;
  extension: string;
  id: string;
  path: string;
  sourceName: string;
};

export type PaymentDocs = {
  description: string;
  docDate: string;
  docId: string;
  droppedAt: string;
  id: string;
  paymentId: string;
  uploadedAt: string;
  userId: string;
  metadata: DocMetadata;
};

export type PaymentDocsResponse = {
  success: boolean;
  docs: PaymentDocs[];
};

export type UpdatePaymentDocResponse = {
  success: boolean;
  doc: PaymentDocs;
};

export type InternationalPaymentsAppStatus =
  | 'AwaitingDocuments'
  | 'Pending'
  | 'Ready'
  | 'Approved'
  | 'Denied'
  | 'Canceled'
  | 'Incomplete';

export type InternationalPaymentsCreateResponse = {
  success: boolean;
  id: string;
  status: InternationalPaymentsAppStatus;
  reason: string;
  checks: unknown[];
};

export type InternationalPaymentsGetResponse = {
  success: boolean;
  status: InternationalPaymentsAppStatus;
  applications: any[];
};

export type IntlPaymentStatus =
  | 'queued'
  | 'ready_to_send'
  | 'completed'
  | 'rejected'
  | 'failed'
  | 'canceled'; // Coming soon when we can 'cancel' international payments.

export type IntlPayment = {
  id: string;
  amount: string;
  reason?: string;
  createdAt: string;
  createdBy: string;
  accountId: string;
  beneficiaryId: string;
  status: IntlPaymentStatus;
  internationalPaymentId?: string;
  ccResponse: {
    currency: AvailableCurrencies;
  };
  paymentReason: string;
  sourceOfFunds: string;
  memo: string;
  estimateAmount?: string;
};

export type IntlPaymentInfoResponse = {
  success: boolean;
  payment: IntlPayment;
};

export type IntlPaymentsResponse = {
  success: boolean;
  payments: IntlPayment[];
};

// All listed as optional as it could be any combination of these fields
export type BeneficiaryRequirements = {
  paymentType?: string;
  acctNumber?: string;
  bicSwift?: string;
  address?: string;
  city?: string;
  dob?: string;
  country?: string;
  postcode?: string;
  stateOrProvince?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  sortCode?: string;
  aba?: string;
  bsbCode?: string;
  institutionNo?: string;
  bankCode?: string;
  branchCode?: string;
  clabe?: string;
  cnaps?: string;
} & ErrorResponse;

export type GetBeneficiaryParameters = {
  bankCountry: string;
  country: string;
  currency: AvailableCurrencies;
  entityType: IndividualOrCompany;
};

/*
Currently only using 'Approved' and 'Denied' but could use other statuses in the future:
https://github.com/flexbase-eng/api/blob/976fa69f9511ff434e9d13a1a06888ecda266606/constants.js#L3407-L3414
*/
type CurrentGetBeneficiariesStatus =
  | 'Approved'
  | 'Denied'
  | 'AwaitingDocuments'
  | 'PendingReview'
  | 'Pending'
  | 'Canceled';

export type GetCompanyBeneficiariesParameters = {
  companyId: string;
  status: CurrentGetBeneficiariesStatus;
};

export type BeneficiaryAddress = {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state?: string;
};

export type CreateBeneficiary = {
  name: string;
  accountHolder: string;
  dob?: string;
  address: BeneficiaryAddress;
  currency: AvailableCurrencies;
  recipientId: string;
} & Omit<BeneficiaryRequirements, 'address'>;

export type FullCreateBeneficiaryResponse = {
  success: boolean;
  beneficiary: BeneficiaryResponse;
};

export type FullGetBeneficiaryResponse = {
  success: boolean;
  beneficiaries: BeneficiaryResponse[];
};

export type GetBeneficiaryRequirementsResponse = {
  success: boolean;
  fields: BeneficiaryRequirements;
};

export type CloseDepositAccountResponse = {
  success: boolean;
  accounts: DepositAccount[];
  error?: string;
};

export type CloseDepositStatusRequest = {
  status: 'closed';
  reason?: string;
  reasonText?: string;
};

export type CheckStatus =
  | 'Sent'
  | 'Pending'
  | 'Clearing'
  | 'PendingReview'
  | 'AwaitingImages'
  | 'AwaitingBackImage'
  | 'AwaitingFrontImage'
  | 'Canceled'
  | 'Rejected'
  | 'Returned';

export type CheckCounterparty = {
  name: string;
  accountNumber: string;
  routingNumber: string;
};

export type CheckDeposit = {
  id: string;
  version: number;
  createdAt: string;
  createdBy: string;
  asOf: string;
  byUser: string;
  amount: number;
  description: string;
  status: CheckStatus;
  clearingDaysOverride: number;
  settlementDate: string;
  checkNumber: string;
  reason: string;
  companyId: string;
  depositAccountId: string;
  tenantId: string;
  counterparty: CheckCounterparty;
};

export type getCheckDepositsListResponse = {
  success: boolean;
  checkDeposits: CheckDeposit[];
};

export type getCheckDepositDataResponse = {
  success: boolean;
  checkDeposit: CheckDeposit;
};

export type getCheckDepositHistoryResponse = {
  success: boolean;
  history: CheckDeposit[];
};

export type ReserveAccount = {
  id: string;
  version: number;
  asOf: string;
  byUser: string;
  companyId: string;
  reserve: number;
  balance: number;
  status: 'Open' | 'Closed';
  createdAt: string;
};

export type ReserveAccountsListResponse = {
  success: boolean;
  reserveAccounts: ReserveAccount[];
};

export type ReserveAccountResponse = {
  success: boolean;
  reserveAccount: ReserveAccount;
};

export type ReserveTransactions = {
  bankingTransactionId: string;
  createdAt: string;
  type: string;
  amount: number;
};

export type ReserveTransactionsResponse = {
  success: boolean;
  reserveTransactions: ReserveTransactions[];
};

export type DepositAccountId = {
  depositAccountId: string;
};

export type EarlyPay = {
  dates: number[];
  graceDays: number;
  rebatePercent: number;
} & DepositAccountId;

export type RepaymentConfig = {
  earlyPay: EarlyPay;
  instantPay: DepositAccountId;
  autoPay: DepositAccountId;
};

export type ChargeCardAccount = {
  id: string;
  version: number;
  type: string;
  asOf: string;
  byUser: string;
  name: string;
  nickName?: string;
  hold: string;
  balance: string;
  available: string;
  fraudReason?: string;
  freezeReason?: string;
  closeReason?: string;
  freezeReasonText?: string;
  createdBy: string;
  status: 'Open' | 'Frozen' | 'Closed';
  accountType: string;
  repaymentConfigurations: RepaymentConfig;
  creditLimit: string;
  remainingAmountDue: number;
  remainingAmountOverdue: number;
  initiatedRepayments: number;
  statementPeriodStart: string;
  statementPeriodEnd: string;
  nextRepaymentDueDate: string;
};

export type ChargeAccountsResponse = {
  accounts: ChargeCardAccount[];
  success: boolean;
};

export type UpdateChargeAccount = {
  name: string;
  repaymentConfigurations: Partial<RepaymentConfig>;
};

export type UpdateChargeAccountResponse = {
  account: ChargeCardAccount;
  success: boolean;
};

export type UploadInvoiceResponse = {
  id: string;
  active: boolean;
  type: string;
  accountId: string;
  createdAt: string;
  lastModifiedAt: string;
  storageUrl: string;
  tags: null;
};
