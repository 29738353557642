import { platformClient } from '../services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

const UQERY_KEY = 'statement';

export const useGetStatements = (
  accountId: string,
  type: 'credit' | 'banking',
  depositAccountId?: string,
) => {
  const errorMessage =
    type === 'credit'
      ? 'Unable to retrieve your charge cards statements at this time.'
      : 'Unable to retrieve banking statements for accounts at this time.';

  return useQuery({
    queryKey: [UQERY_KEY, 'list', type, accountId, depositAccountId],
    queryFn: async () => {
      return await platformClient.getListOfStatements(accountId, {
        type,
        ...(depositAccountId && { accountId: depositAccountId }),
      });
    },
    enabled: !!accountId,
    meta: {
      errorMessage,
    },
  });
};
