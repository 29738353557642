import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';
import {
  IsSuccessResponse,
  PKCE,
  RegisterAuthenticationFactorResponseModel,
  RegisterOtpFactorModel,
  RegisterTotpFactorModel,
} from '../services/platform/models/authorize.models';
import { createContext, ReactNode, useContext, useState } from 'react';
import {
  useDeleteAuthFactorMutation,
  useGetAuthenticationFactors,
  useRegisterAuthFactorMutation,
} from '../queries/use-authentication-factors';
import {
  useCreatePlatformPersonPhoneMutation,
  useGetMe,
  useGetPlatformPersonPhones,
} from '../queries/use-get-me';
import { PlatformPhone } from '../services/platform/models/identity.model';
import { useAuthToken } from '../states/auth/auth-token';
import { platformAuthClient } from '../services/platform/platform-auth-client';
import { formatPhoneForApi } from '@flexbase-eng/web-components';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

// This type is here to drop the `codeChallenge` property as the context and not the caller will control this, so it won't need to be passed in
type AddFactorRequest =
  | Omit<RegisterOtpFactorModel, 'codeChallenge'>
  | Omit<RegisterTotpFactorModel, 'codeChallenge'>;

type AddFactorsContextReturnType = {
  /**
   * A list of the user's non-email possesion factors
   */
  possessionFactors: AuthenticationFactor[];

  /**
   * A list of the user's phones as stored in Platform
   */
  personPhones: PlatformPhone[];

  /**
   * Register either an OTP (phone/SMS) or a TOTP (authenticator app) factor.
   * If the factor is an OTP factor, this function will create a Phone for this user in addition to adding it as a factor.
   * If the factor is an OTP factor, an SMS code is automatically sent to the phone. (By Platform)
   * @param factor
   */
  registerFactor: (
    factor: AddFactorRequest,
  ) => Promise<RegisterAuthenticationFactorResponseModel>;

  /**
   * Delete an authentication factor by methodId
   * @param methodId
   */
  deleteFactor: (methodId: string) => Promise<void>;

  /**
   * Resend the SMS code to the OTP factor. This will throw an error if the provided methodId does not exist or
   * does not match an OTP/SMS factor.
   * @param methodId
   */
  resendOtpSmsVerification: (
    otpFactor: Omit<RegisterOtpFactorModel, 'codeChallenge'>,
  ) => Promise<RegisterAuthenticationFactorResponseModel>;

  /**
   * Verify a code for the provided method id. This function has a side effect of updating the user's current authentication token and data.
   * @param methodId
   * @param factorType
   * @param code
   */
  verifyFactor: (
    methodId: string,
    factorType: 'otp' | 'totp',
    code: string,
  ) => Promise<void>;

  /**
   * This function saves a phone number to the appropriate places (platform.person.phones and api.user.cell_phone and api.user.phone). This is meant to be
   * called post-verification, and while this isn't necessary for adding an OTP factor, it is generally part of workflow, so it is available here.
   * @param phoneNumber 10 digit phone number, with or without dashes. Ex: 555-555-5555 or 5555555555.
   */
  savePhone: (phoneNumber: string) => Promise<void>;
};

const AddFactorsContext = createContext<AddFactorsContextReturnType | null>(
  null,
);

/**
 * Use this to wrap a component that will allow a user to add an authentication factors.
 */
export const AddFactorsProvider = ({ children }: { children: ReactNode }) => {
  const { signIn } = useAuthToken();
  const { data: allFactors } = useGetAuthenticationFactors();
  const { data: person } = useGetMe();
  const { data: phones } = useGetPlatformPersonPhones(
    person?.accountId,
    person?.id,
  );

  const [pkce, setPkce] = useState<PKCE | null>(null);

  const { mutateAsync: _deleteFactor } = useDeleteAuthFactorMutation();
  const { mutateAsync: _registerFactor } = useRegisterAuthFactorMutation();
  const { mutate: _createPersonPhone } = useCreatePlatformPersonPhoneMutation();

  const registerFactor = async (factor: AddFactorRequest) => {
    const _pkce = await platformAuthClient.generatePKCE();
    const registerFactorRequest = {
      ...factor,
      codeChallenge: _pkce.codeChallenge,
    };

    setPkce(_pkce);

    const result = await _registerFactor({
      factor: registerFactorRequest,
    });
    return result.body!;
  };

  const deleteFactor = async (methodId: string) => {
    await _deleteFactor(methodId);
  };

  const resendOtpSmsVerification = async (
    factor: Omit<RegisterOtpFactorModel, 'codeChallenge'>,
  ) => {
    // If a factor is not verified, a register request instead reissues the SMS code. This function is only here for naming clarity
    return await registerFactor(factor);
  };

  const verifyFactor = async (
    methodId: string,
    factorType: 'otp' | 'totp',
    code: string,
  ) => {
    if (!pkce) {
      throw new Error('PKCE must not be null when verifying a factor');
    }

    const tokenResponse = await platformAuthClient.requestTokenByCode({
      code,
      grantType: factorType,
      methodId: methodId,
      codeVerifier: pkce.codeVerifier,
    });

    if (!IsSuccessResponse(tokenResponse) || !tokenResponse.body) {
      throw (
        tokenResponse.wwwAuthenticate ??
        tokenResponse.error ??
        'Unknown code verification error.'
      );
    }

    signIn(tokenResponse.body);
  };

  const savePhone = async (phoneNumber: string) => {
    const phoneFormattedForApi = formatPhoneForApi(phoneNumber);
    const phoneFormattedForPlatform = `+1${phoneFormattedForApi}`;

    if (
      !personPhones.find((p) => p.value === phoneFormattedForPlatform) &&
      person
    ) {
      // At this time we don't actually care if the Platform phone creates succeeds, so using mutate instead of mutateAsync is a-ok
      _createPersonPhone({
        personId: person.id,
        accountId: person.accountId,
        phoneId: '', // This is expected. The same model is shared between create and updates, and it can't be changed without refactoring other code (will do soonTM).
        update: {
          type: 'mobile',
          value: phoneFormattedForPlatform,
          active: true,
          isPrimary: true,
        },
      });
    }

    await flexbaseOnboardingClient.updateUser({
      phone: phoneFormattedForApi,
      cellPhone: phoneFormattedForApi,
    });
  };

  const possessionFactors =
    allFactors?.possession.filter((d) => d.method !== 'email') ?? [];
  const personPhones = phones?.data ?? [];

  return (
    <AddFactorsContext.Provider
      value={{
        registerFactor,
        deleteFactor,
        possessionFactors,
        personPhones,
        resendOtpSmsVerification,
        verifyFactor,
        savePhone,
      }}
    >
      {children}
    </AddFactorsContext.Provider>
  );
};

/**
 * This context provides functionality to register and verify a factor.
 *
 * Warning: This depends on `usePlatformPersonContext` so the consuming component MUST be wrapped in PlatformPersonProvider!
 */
export const useAddFactorsContext = () => {
  const context = useContext(AddFactorsContext);

  if (context === null) {
    throw new Error('useAddFactorsContext must be used within addFactors');
  }

  return context;
};
