import { Container, Flex, Text, useMantineTheme } from '@mantine/core';
import { useGetSpendPlanTransactions } from '../../../../queries/use-spend-plans';
import { formatCurrency } from '../../../../utilities/formatters/format-currency';
import { DateTime } from 'luxon';
import { usePlatformPersonContext } from '../../../../providers/platform-person.context';
import { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

type Props = {
  frequency: string;
  limit: number;
  spendPlanId: string;
};

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={12}
        y={0}
        dy={5}
        dx={0}
        textAnchor="end"
        fill="#666"
        fontSize="10px"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={0}
        dx={0}
        fontSize="10px"
        textAnchor="end"
        fill="#666"
      >
        {formatCurrency(payload.value)}
      </text>
    </g>
  );
};

const UsageTile = ({ frequency, limit, spendPlanId }: Props) => {
  const themeColos = useMantineTheme();
  const { person } = usePlatformPersonContext();

  const { data } = useGetSpendPlanTransactions({
    accountId: person.accountId,
    spendPlanId: spendPlanId || '',
  });

  const chartData = useMemo(() => {
    if (data) {
      return data.map((chart) => {
        return {
          date: DateTime.fromFormat(chart.date, 'yyyy-mm-dd').toFormat(
            'LLL dd',
          ),
          amount: chart.amount,
        };
      });
    }
  }, [data]);

  return (
    <Container
      bg="neutral.0"
      h={260}
      p={20}
      sx={(theme) => ({
        border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
        borderRadius: '2px',
      })}
    >
      <Flex direction="row" justify="space-between" align="center" mb={5}>
        <Text fz="20px"> Usage </Text>
        <Text fz="14px"> {frequency} </Text>
      </Flex>
      <ResponsiveContainer
        width="100%"
        height="100%"
        style={{ marginBottom: 20 }}
      >
        <BarChart
          barSize={19}
          height={300}
          data={chartData}
          barCategoryGap={6}
          barGap={6}
          margin={{
            top: 20,
            right: 3,
            left: 3,
            bottom: 20,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dataKey="date"
            fontSize="10px"
            tickLine={false}
            tick={<CustomizedAxisTick />}
            domain={[DateTime.now().toFormat('LLL dd')]}
          />
          <YAxis
            domain={[0, limit]}
            tick={<CustomYAxisTick />}
            fontSize="10px"
            tickLine={false}
            axisLine={false}
          />
          <Bar
            dataKey="amount"
            fill={themeColos.fn.themeColor('primarySecondarySuccess', 0)}
            stroke={themeColos.fn.themeColor('primarySecondarySuccess', 2)}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};
export default UsageTile;
